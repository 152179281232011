@charset "UTF-8";
/* ==========================================================================
   Helper classes
   ========================================================================== */
.hidden {
  display: none !important;
  visibility: hidden; }

.clearfix:before, .clr:before {
  content: "";
  display: table; }

.clearfix:after, .clr:after {
  content: "";
  clear: both;
  display: table; }

.f-left {
  float: left; }

.f-right {
  float: right; }

.center {
  text-align: center; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.inl-block {
  display: inline-block;
  *display: inline;
  /* IE < 8: fake inline-block */
  zoom: 1;
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  *vertical-align: auto;
  text-rendering: auto; }

.f-wr-align {
  display: flex;
  align-items: center;
  justify-content: center; }

.abs-vert {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%); }

.f-wr-item {
  flex: none;
  max-width: 50%; }

.f-vert-align {
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.list-unstyled {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.oinvisible {
  visibility: hidden;
  opacity: 0; }

.ovisible {
  visibility: visible;
  opacity: 1; }

.front-menu li, .bb-top, .acc-item .img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center; }

.btn-round:before, .btn-round:after {
  font-family: "icons";
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.btn-round span, .slick-arrow.slick-prev .ico, .slick-arrow.slick-next .ico {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%); }

@font-face {
  font-family: 'icons';
  src: url("../../../theme_fifth/fonts/icons/icons.eot?aj9l4z");
  src: url("../../../theme_fifth/fonts/icons/icons.eot?aj9l4z#iefix") format("embedded-opentype"), url("../../../theme_fifth/fonts/icons/icons.ttf?aj9l4z") format("truetype"), url("../../../theme_fifth/fonts/icons/icons.woff?aj9l4z") format("woff"), url("../../../theme_fifth/fonts/icons/icons.svg?aj9l4z#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ico-arrow:before {
  content: ""; }

.ico-bed:before {
  content: ""; }

.ico-chevron-double:before, .btn-round:before, .btn-round:after {
  content: ""; }

.ico-close:before {
  content: ""; }

.ico-fb:before {
  content: ""; }

.ico-gp:before {
  content: ""; }

.ico-menu:before {
  content: ""; }

.ico-star:before {
  content: ""; }

.ico-tw:before {
  content: ""; }

html, body {
  height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  position: relative; }

body {
  margin: 0;
  padding: 0; }

[tabindex='-1']:focus {
  outline: none; }

.inset {
  padding: 10px; }

button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button; }
  input[type="reset"][disabled],
  input[type="submit"][disabled],
  html input[type="button"][disabled],
  button[disabled] {
    cursor: default; }

textarea {
  vertical-align: top;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

html, body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

md-select, md-card, md-list, md-toolbar,
ul, ol, p, h1, h2, h3, h4, h5, h6 {
  text-rendering: optimizeLegibility; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.3); }

@media (min-width: 768px) {
  .md-padding {
    padding: 16px; } }

md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50; }
  md-backdrop.md-menu-backdrop {
    position: fixed !important;
    z-index: 99; }
  md-backdrop.md-select-backdrop {
    z-index: 81;
    transition-duration: 0; }
  md-backdrop.md-dialog-backdrop {
    z-index: 79; }
  md-backdrop.md-bottom-sheet-backdrop {
    z-index: 69; }
  md-backdrop.md-sidenav-backdrop {
    z-index: 59; }
  md-backdrop.md-click-catcher {
    position: absolute; }
  md-backdrop.md-opaque {
    opacity: .48; }
    md-backdrop.md-opaque.ng-enter {
      opacity: 0; }
    md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
      opacity: .48; }
    md-backdrop.md-opaque.ng-leave {
      opacity: .48;
      transition: opacity 400ms; }
    md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
      opacity: 0; }

button.md-button::-moz-focus-inner {
  border: 0; }

.md-button {
  box-sizing: border-box;
  color: currentColor;
  user-select: none;
  position: relative;
  z-index: 1;
  outline: none;
  border: 0;
  display: block;
  align-items: center;
  padding: 25px;
  margin: 10px auto;
  line-height: 18px;
  min-height: 18px;
  background: #2b7ec3;
  white-space: nowrap;
  max-width: 298px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 1.5rem;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-button:focus {
    outline: none; }
  .md-button:hover, .md-button:focus {
    text-decoration: none; }
  .md-button.ng-hide, .md-button.ng-leave {
    transition: none; }
  .md-button.md-button-yellow {
    background: #e1b900; }
    .md-button.md-button-yellow:before {
      background: #ffd200; }
  .md-button.md-cornered {
    border-radius: 0; }
  .md-button.md-icon {
    padding: 0;
    background: none; }
  .md-button.md-bordered {
    position: relative;
    z-index: 2;
    top: -5px;
    -webkit-transition: all 0.05s linear;
    -moz-transition: all 0.05s linear;
    transition: all 0.05s linear; }
    .md-button.md-bordered:before {
      content: "";
      display: block;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 5px;
      left: 0;
      position: absolute;
      -webkit-transition: all 0.05s linear;
      -moz-transition: all 0.05s linear;
      transition: all 0.05s linear; }
    .md-button.md-bordered:active {
      top: 0; }
      .md-button.md-bordered:active:before {
        bottom: 0; }
  .md-button.md-button-small {
    padding: 15px 20px;
    max-width: 298px;
    color: #fff;
    font-size: 1rem; }
  .md-button.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button.md-icon-button {
    margin: 0 10px;
    height: 48px;
    min-width: 0;
    line-height: 24px;
    padding: 10px;
    width: 48px;
    border-radius: 0; }
    .md-button.md-icon-button .md-ripple-container {
      border-radius: 0;
      background-clip: padding-box;
      overflow: hidden;
      -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC"); }
  .md-button.md-fab {
    z-index: 20;
    line-height: 24px;
    min-width: 0;
    width: 280px;
    height: 48px;
    vertical-align: middle;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 0;
    background-clip: padding-box;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-property: background-color, box-shadow, transform; }
    .md-button.md-fab.md-fab-bottom-right {
      top: auto;
      right: 280px-10px 20px/2;
      bottom: 48px-10px 20px/2;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-bottom-left {
      top: auto;
      right: auto;
      bottom: 48px-10px 20px/2;
      left: 280px-10px 20px/2;
      position: absolute; }
    .md-button.md-fab.md-fab-top-right {
      top: 48px-10px 20px/2;
      right: 280px-10px 20px/2;
      bottom: auto;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-top-left {
      top: 48px-10px 20px/2;
      right: auto;
      bottom: auto;
      left: 280px-10px 20px/2;
      position: absolute; }
    .md-button.md-fab .md-ripple-container {
      border-radius: 0;
      background-clip: padding-box;
      overflow: hidden;
      -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC"); }
    .md-button.md-fab.md-mini {
      line-height: 18px;
      width: 120px;
      height: 48px; }
    .md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
      transition: none; }
  .md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }
  .md-button .md-ripple-container {
    border-radius: 0;
    background-clip: padding-box;
    overflow: hidden;
    -webkit-mask-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC"); }
  @media screen and (max-width: 59.9375em) {
    .md-button {
      padding: 15px 20px;
      max-width: 298px;
      color: #fff;
      font-size: 1rem; } }

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 36px, 0); }
  .md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
    transform: translate3d(0, 35px, 0); }

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -36px, 0); }
  .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
    transform: translate3d(0, -37px, 0); }

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%; }

.md-button-group > .md-button {
  flex: 1;
  display: block;
  overflow: hidden;
  width: 0;
  border-width: 1px 0px 1px 1px;
  border-radius: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .md-button-group > .md-button:first-child {
    border-radius: 2px 0px 0px 2px; }
  .md-button-group > .md-button:last-child {
    border-right-width: 1px;
    border-radius: 0px 2px 2px 0px; }

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff; } }

.btn-round {
  font-size: 0.75rem;
  background: #2b7ec3;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  overflow: visible;
  height: 120px;
  width: 120px; }
  .btn-round .md-ripple-container {
    border-radius: 50%; }
  .btn-round:before, .btn-round:after {
    position: absolute;
    top: 50%;
    right: -0.3125em;
    color: #fff;
    font-size: 36px;
    z-index: 2;
    -webkit-transform: translateY(-50%) translateX(100%);
    -moz-transform: translateY(-50%) translateX(100%);
    -ms-transform: translateY(-50%) translateX(100%);
    -o-transform: translateY(-50%) translateX(100%);
    transform: translateY(-50%) translateX(100%); }
  .btn-round:after {
    right: auto;
    left: -0.3125em;
    -webkit-transform: scale(-1, 1) translateY(-50%) translateX(100%);
    -moz-transform: scale(-1, 1) translateY(-50%) translateX(100%);
    -ms-transform: scale(-1, 1) translateY(-50%) translateX(100%);
    -o-transform: scale(-1, 1) translateY(-50%) translateX(100%);
    transform: scale(-1, 1) translateY(-50%) translateX(100%); }
  @media screen and (min-width: 35.5em) {
    .btn-round {
      font-size: 1rem;
      height: 160px;
      width: 160px; }
      .btn-round:before, .btn-round:after {
        font-size: 50px; } }
  @media screen and (min-width: 48em) {
    .btn-round {
      font-size: 1.25rem;
      height: 191px;
      width: 191px; } }

.btn-sidebar {
  color: #333333;
  background: none; }

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 0;
  background: #4a97d7;
  height: -webkit-calc(100% - 60px);
  height: calc(100% - 60px);
  overflow: auto;
  -webkit-transform: translateX(8px);
  -moz-transform: translateX(8px);
  -ms-transform: translateX(8px);
  -o-transform: translateX(8px);
  transform: translateX(8px); }
  .md-open-menu-container md-menu-divider {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    width: 100%; }
  .md-open-menu-container md-menu-content > * {
    opacity: 0; }
  .md-open-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-open-menu-container.md-active {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 200ms; }
    .md-open-menu-container.md-active > md-menu-content > * {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 200ms;
      transition-delay: 100ms; }
  .md-open-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }
  @media screen and (min-width: 48em) {
    .md-open-menu-container {
      height: auto; } }
  @media screen and (min-width: 1260px) {
    .md-open-menu-container {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px); } }
  @media screen and (max-width: 35.5em) {
    .md-open-menu-container {
      top: 60px !important;
      -webkit-transform: translateX(-8px);
      -moz-transform: translateX(-8px);
      -ms-transform: translateX(-8px);
      -o-transform: translateX(-8px);
      transform: translateX(-8px); } }

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto; }
  md-menu-content.md-dense {
    max-height: 260px; }
    md-menu-content.md-dense md-menu-item {
      min-height: 0px; }

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 60px;
  align-content: center;
  justify-content: flex-start; }
  md-menu-item > * {
    width: 100%;
    margin: auto 0;
    padding-left: 20px;
    padding-right: 20px; }
  md-menu-item > a.md-button {
    display: flex; }
  md-menu-item a {
    color: #fff;
    font-size: 0.75rem;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    padding: 15px 20px;
    font-weight: 700;
    border-bottom: 1px solid #358bd2;
    -webkit-transition: color 0.15s linear;
    -moz-transition: color 0.15s linear;
    transition: color 0.15s linear; }
    md-menu-item a:hover {
      background: #2b7ec3; }
  md-menu-item:last-child a {
    border-bottom: none; }
  md-menu-item.active a {
    color: #fff;
    background: #2b7ec3; }
  md-menu-item > .md-button {
    border-radius: 0;
    margin: auto 0;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    text-align: left;
    text-align: start;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-block;
    align-items: baseline;
    align-content: flex-start;
    width: 100%; }
    md-menu-item > .md-button md-icon {
      margin: auto 20px auto 0; }
    md-menu-item > .md-button p {
      display: inline-block;
      margin: auto; }
    md-menu-item > .md-button span {
      margin-top: auto;
      margin-bottom: auto; }
    md-menu-item > .md-button .md-ripple-container {
      border-radius: inherit; }

.md-menu {
  padding: 10px 0; }

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0; }

@media (max-width: 767px) {
  md-menu-content {
    min-width: 112px; }
  md-menu-content[width="3"] {
    min-width: 168px; }
  md-menu-content[width="4"] {
    min-width: 224px; }
  md-menu-content[width="5"] {
    min-width: 280px; }
  md-menu-content[width="6"] {
    min-width: 336px; }
  md-menu-content[width="7"] {
    min-width: 392px; } }

@media (min-width: 768px) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 192px; }
  md-menu-content[width="4"] {
    min-width: 256px; }
  md-menu-content[width="5"] {
    min-width: 320px; }
  md-menu-content[width="6"] {
    min-width: 384px; }
  md-menu-content[width="7"] {
    min-width: 448px; } }

@media (max-width: 35.5em) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 100vw; }
  md-menu-content[width="4"] {
    min-width: 100vw; }
  md-menu-content[width="5"] {
    min-width: 100vw; }
  md-menu-content[width="6"] {
    min-width: 100vw; }
  md-menu-content[width="7"] {
    min-width: 100vw; } }

md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 35px;
  padding-bottom: 15px; }

md-menu-bar {
  padding: 0;
  display: block;
  position: relative;
  z-index: 2; }
  md-menu-bar .ico {
    font-size: 1.75rem;
    position: relative;
    top: 1px; }
  md-menu-bar .md-menu {
    display: inline-block;
    padding: 0;
    position: relative; }
  md-menu-bar button {
    color: #333333;
    font-size: 0.75rem;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 25px;
    font-weight: 700;
    -webkit-transition: color 0.15s linear;
    -moz-transition: color 0.15s linear;
    transition: color 0.15s linear;
    outline: 0;
    margin: 0;
    border: 0;
    background-color: transparent; }
  md-menu-bar.md-open button {
    color: #fff;
    background: #2b7ec3; }
  md-menu-bar md-backdrop.md-menu-backdrop {
    z-index: -2; }
  @media screen and (min-width: 60em) {
    md-menu-bar button {
      padding: 28px 25px 29px; } }

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 10px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
    position: relative; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      position: absolute;
      padding: 0;
      width: 24px;
      top: 7.5px;
      left: 30px; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
      padding: 0 40px 0 80px; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button {
    min-height: 0;
    height: 40px;
    display: flex; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span {
      flex-grow: 1; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
      flex-grow: 0;
      align-self: flex-end;
      margin: 0 10px; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
    margin: 10px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
    text-align: left;
    text-align: start; }
  md-menu-content.md-menu-bar-menu.md-dense .md-menu {
    padding: 0; }
    md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      position: relative;
      margin: 0;
      width: 100%;
      text-transform: none;
      font-weight: normal;
      border-radius: 0px;
      padding-left: 20px; }
      md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
        display: block;
        content: '\25BC';
        position: absolute;
        top: 0px;
        speak: none;
        transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
        right: 35px; }

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0; }

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0; }
  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 248px; }
  md-tabs[md-align-tabs="bottom"] {
    padding-bottom: 48px; }
    md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      z-index: 2; }
    md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
      top: 0;
      bottom: 48px; }
  md-tabs.md-dynamic-height md-tabs-content-wrapper {
    min-height: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    overflow: hidden; }
  md-tabs.md-dynamic-height md-tab-content.md-active {
    position: relative; }
  md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 49px; }
  @media screen and (min-width: 48em) {
    md-tabs {
      overflow: visible; }
      md-tabs.md-dynamic-height {
        overflow: visible; } }

md-tabs-wrapper {
  display: block;
  font-weight: 700;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
    height: 100%;
    width: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1em;
    z-index: 2;
    cursor: pointer;
    font-size: 16px;
    background: transparent no-repeat center center;
    transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
    md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
      outline: none; }
    md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
      opacity: 0.25;
      cursor: default; }
    md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
      transition: none; }
    md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 100%;
      height: auto; }
  md-tabs-wrapper md-prev-button {
    left: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg=="); }
  md-tabs-wrapper md-next-button {
    right: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K"); }
    md-tabs-wrapper md-next-button md-icon {
      transform: translate3d(-50%, -50%, 0) rotate(180deg); }
  md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row; }
    md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
      flex-grow: 1; }
  @media screen and (min-width: 48em) {
    md-tabs-wrapper {
      position: absolute;
      top: 0;
      left: 1px;
      width: 33.5%;
      -webkit-transform: translate3d(-100%, 0, 0);
      -moz-transform: translate3d(-100%, 0, 0);
      -ms-transform: translate3d(-100%, 0, 0);
      -o-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0); } }
  @media screen and (min-width: 80em) {
    md-tabs-wrapper {
      width: 50%; } }

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px; }
  md-tabs-canvas:after {
    content: '';
    display: table;
    clear: both; }
  md-tabs-canvas .md-dummy-wrapper {
    position: absolute;
    top: 0;
    left: 0; }
  md-tabs-canvas.md-paginated {
    margin: 0 30px; }
  md-tabs-canvas.md-center-tabs {
    display: flex;
    flex-direction: column;
    text-align: center; }
    md-tabs-canvas.md-center-tabs .md-tab {
      float: none;
      display: inline-block; }
  md-tabs-canvas .md-ripple-container {
    display: none; }
  @media screen and (min-width: 48em) {
    md-tabs-canvas {
      height: auto; } }

md-pagination-wrapper {
  height: 48px;
  display: block;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  width: 999999px;
  left: 0;
  transform: translate3d(0, 0, 0); }
  md-pagination-wrapper:after {
    content: '';
    display: table;
    clear: both; }
  md-pagination-wrapper.md-center-tabs {
    position: relative;
    width: initial;
    margin: 0 auto; }
  @media screen and (min-width: 48em) {
    md-pagination-wrapper {
      height: auto;
      position: relative;
      width: 100% !important; } }

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

md-tab-content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  padding: 0 1px;
  transform: translate3d(0, 0, 0); }
  md-tab-content.md-no-scroll {
    bottom: auto;
    overflow: hidden; }
  md-tab-content.ng-leave, md-tab-content.md-no-transition {
    transition: none; }
  md-tab-content.md-left:not(.md-active) {
    transform: translateX(-100%);
    animation: 1s md-tab-content-hide;
    opacity: 0; }
    md-tab-content.md-left:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content.md-right:not(.md-active) {
    transform: translateX(100%);
    animation: 1s md-tab-content-hide;
    opacity: 0; }
    md-tab-content.md-right:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content > div.ng-leave {
    animation: 1s md-tab-content-hide; }

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px; }
  @media screen and (min-width: 48em) {
    md-tab {
      z-index: 1;
      left: auto; } }

.md-tab {
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  float: left;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  transition: all 0.15s linear; }
  .md-tab p {
    margin: 0 3px;
    padding: 15px 10px;
    line-height: 18px;
    background: #d0d0d0;
    color: #fff !important;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear; }
  .md-tab.md-focused {
    box-shadow: none;
    outline: none; }
  .md-tab.md-active {
    cursor: default; }
    .md-tab.md-active p {
      background: #2b7ec3; }
  .md-tab.md-disabled {
    pointer-events: none;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    opacity: 0.5;
    cursor: default; }
  .md-tab.ng-leave {
    transition: none; }
  @media screen and (min-width: 568px) {
    .md-tab p {
      padding: 15px 25px; } }
  @media screen and (min-width: 48em) {
    .md-tab {
      width: 100%;
      white-space: normal;
      float: none; } }

md-toolbar + md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative; }
  md-grid-list md-grid-tile,
  md-grid-list md-grid-tile > figure,
  md-grid-list md-grid-tile-header,
  md-grid-list md-grid-tile-footer {
    box-sizing: border-box; }
  md-grid-list md-grid-tile {
    display: block;
    position: absolute; }
    md-grid-list md-grid-tile figure {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0; }
    md-grid-list md-grid-tile md-grid-tile-header,
    md-grid-list md-grid-tile md-grid-tile-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h3,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-weight: 400;
        margin: 0 0 0 16px; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-footer h3 {
        font-size: 14px; }
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-size: 12px; }
    md-grid-list md-grid-tile md-grid-tile-header {
      top: 0; }
    md-grid-list md-grid-tile md-grid-tile-footer {
      bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff; }
  md-grid-tile-footer {
    border-top: 1px solid #fff; } }

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*/
@-moz-document url-prefix() {
  [layout-fill] {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/* Apply Mixins to create Layout/Flexbox styles */
[flex-order] {
  order: 0; }

[flex-order="-20"] {
  order: -20; }

[flex-order="-19"] {
  order: -19; }

[flex-order="-18"] {
  order: -18; }

[flex-order="-17"] {
  order: -17; }

[flex-order="-16"] {
  order: -16; }

[flex-order="-15"] {
  order: -15; }

[flex-order="-14"] {
  order: -14; }

[flex-order="-13"] {
  order: -13; }

[flex-order="-12"] {
  order: -12; }

[flex-order="-11"] {
  order: -11; }

[flex-order="-10"] {
  order: -10; }

[flex-order="-9"] {
  order: -9; }

[flex-order="-8"] {
  order: -8; }

[flex-order="-7"] {
  order: -7; }

[flex-order="-6"] {
  order: -6; }

[flex-order="-5"] {
  order: -5; }

[flex-order="-4"] {
  order: -4; }

[flex-order="-3"] {
  order: -3; }

[flex-order="-2"] {
  order: -2; }

[flex-order="-1"] {
  order: -1; }

[flex-order="0"] {
  order: 0; }

[flex-order="1"] {
  order: 1; }

[flex-order="2"] {
  order: 2; }

[flex-order="3"] {
  order: 3; }

[flex-order="4"] {
  order: 4; }

[flex-order="5"] {
  order: 5; }

[flex-order="6"] {
  order: 6; }

[flex-order="7"] {
  order: 7; }

[flex-order="8"] {
  order: 8; }

[flex-order="9"] {
  order: 9; }

[flex-order="10"] {
  order: 10; }

[flex-order="11"] {
  order: 11; }

[flex-order="12"] {
  order: 12; }

[flex-order="13"] {
  order: 13; }

[flex-order="14"] {
  order: 14; }

[flex-order="15"] {
  order: 15; }

[flex-order="16"] {
  order: 16; }

[flex-order="17"] {
  order: 17; }

[flex-order="18"] {
  order: 18; }

[flex-order="19"] {
  order: 19; }

[flex-order="20"] {
  order: 20; }

[flex-offset="0"] {
  margin-left: 0%; }

[flex-offset="5"] {
  margin-left: 5%; }

[flex-offset="10"] {
  margin-left: 10%; }

[flex-offset="15"] {
  margin-left: 15%; }

[flex-offset="20"] {
  margin-left: 20%; }

[flex-offset="25"] {
  margin-left: 25%; }

[flex-offset="30"] {
  margin-left: 30%; }

[flex-offset="35"] {
  margin-left: 35%; }

[flex-offset="40"] {
  margin-left: 40%; }

[flex-offset="45"] {
  margin-left: 45%; }

[flex-offset="50"] {
  margin-left: 50%; }

[flex-offset="55"] {
  margin-left: 55%; }

[flex-offset="60"] {
  margin-left: 60%; }

[flex-offset="65"] {
  margin-left: 65%; }

[flex-offset="70"] {
  margin-left: 70%; }

[flex-offset="75"] {
  margin-left: 75%; }

[flex-offset="80"] {
  margin-left: 80%; }

[flex-offset="85"] {
  margin-left: 85%; }

[flex-offset="90"] {
  margin-left: 90%; }

[flex-offset="95"] {
  margin-left: 95%; }

[flex-offset="33"] {
  margin-left: calc(100% / 3); }

[flex-offset="66"] {
  margin-left: calc(200% / 3); }

[layout-align],
[layout-align="start stretch"] {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

[layout-align="start"],
[layout-align="start start"],
[layout-align="start center"],
[layout-align="start end"],
[layout-align="start stretch"] {
  justify-content: flex-start; }

[layout-align="center"],
[layout-align="center start"],
[layout-align="center center"],
[layout-align="center end"],
[layout-align="center stretch"] {
  justify-content: center; }

[layout-align="end"],
[layout-align="end center"],
[layout-align="end start"],
[layout-align="end end"],
[layout-align="end stretch"] {
  justify-content: flex-end; }

[layout-align="space-around"],
[layout-align="space-around center"],
[layout-align="space-around start"],
[layout-align="space-around end"],
[layout-align="space-around stretch"] {
  justify-content: space-around; }

[layout-align="space-between"],
[layout-align="space-between center"],
[layout-align="space-between start"],
[layout-align="space-between end"],
[layout-align="space-between stretch"] {
  justify-content: space-between; }

[layout-align="start start"],
[layout-align="center start"],
[layout-align="end start"],
[layout-align="space-between start"],
[layout-align="space-around start"] {
  align-items: flex-start;
  align-content: flex-start; }

[layout-align="start center"],
[layout-align="center center"],
[layout-align="end center"],
[layout-align="space-between center"],
[layout-align="space-around center"] {
  align-items: center;
  align-content: center;
  max-width: 100%; }

[layout-align="start center"] > *,
[layout-align="center center"] > *,
[layout-align="end center"] > *,
[layout-align="space-between center"] > *,
[layout-align="space-around center"] > * {
  max-width: 100%;
  box-sizing: border-box; }

[layout-align="start end"],
[layout-align="center end"],
[layout-align="end end"],
[layout-align="space-between end"],
[layout-align="space-around end"] {
  align-items: flex-end;
  align-content: flex-end; }

[layout-align="start stretch"],
[layout-align="center stretch"],
[layout-align="end stretch"],
[layout-align="space-between stretch"],
[layout-align="space-around stretch"] {
  align-items: stretch;
  align-content: stretch; }

[flex] {
  flex: 1;
  box-sizing: border-box; }

@media screen\0 {
  [flex] {
    flex: 1 1 0%; } }

[flex-grow] {
  flex: 1 1 100%;
  box-sizing: border-box; }

[flex-initial] {
  flex: 0 1 auto;
  box-sizing: border-box; }

[flex-auto] {
  flex: 1 1 auto;
  box-sizing: border-box; }

[flex-none] {
  flex: 0 0 auto;
  box-sizing: border-box; }

[flex="0"] {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="0"],
[layout="row"] > [flex="0"] {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="0"],
[layout="column"] > [flex="0"] {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box; }

[flex="5"] {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="5"],
[layout="row"] > [flex="5"] {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="5"],
[layout="column"] > [flex="5"] {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

[flex="10"] {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="10"],
[layout="row"] > [flex="10"] {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="10"],
[layout="column"] > [flex="10"] {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

[flex="15"] {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="15"],
[layout="row"] > [flex="15"] {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="15"],
[layout="column"] > [flex="15"] {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

[flex="20"] {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="20"],
[layout="row"] > [flex="20"] {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="20"],
[layout="column"] > [flex="20"] {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

[flex="25"] {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="25"],
[layout="row"] > [flex="25"] {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="25"],
[layout="column"] > [flex="25"] {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

[flex="30"] {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="30"],
[layout="row"] > [flex="30"] {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="30"],
[layout="column"] > [flex="30"] {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

[flex="35"] {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="35"],
[layout="row"] > [flex="35"] {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="35"],
[layout="column"] > [flex="35"] {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

[flex="40"] {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="40"],
[layout="row"] > [flex="40"] {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="40"],
[layout="column"] > [flex="40"] {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

[flex="45"] {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="45"],
[layout="row"] > [flex="45"] {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="45"],
[layout="column"] > [flex="45"] {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

[flex="50"] {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="50"],
[layout="row"] > [flex="50"] {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="50"],
[layout="column"] > [flex="50"] {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

[flex="55"] {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="55"],
[layout="row"] > [flex="55"] {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="55"],
[layout="column"] > [flex="55"] {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

[flex="60"] {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="60"],
[layout="row"] > [flex="60"] {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="60"],
[layout="column"] > [flex="60"] {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

[flex="65"] {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="65"],
[layout="row"] > [flex="65"] {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="65"],
[layout="column"] > [flex="65"] {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

[flex="70"] {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="70"],
[layout="row"] > [flex="70"] {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="70"],
[layout="column"] > [flex="70"] {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

[flex="75"] {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="75"],
[layout="row"] > [flex="75"] {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="75"],
[layout="column"] > [flex="75"] {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

[flex="80"] {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="80"],
[layout="row"] > [flex="80"] {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="80"],
[layout="column"] > [flex="80"] {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

[flex="85"] {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="85"],
[layout="row"] > [flex="85"] {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="85"],
[layout="column"] > [flex="85"] {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

[flex="90"] {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="90"],
[layout="row"] > [flex="90"] {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="90"],
[layout="column"] > [flex="90"] {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

[flex="95"] {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="95"],
[layout="row"] > [flex="95"] {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="95"],
[layout="column"] > [flex="95"] {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

[flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="100"],
[layout="row"] > [flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="100"],
[layout="column"] > [flex="100"] {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="33"], [layout="row"] > [flex="33"], [layout="row"] > [flex="33"], [layout="row"] > [flex="33"] {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="row"] > [flex="66"], [layout="row"] > [flex="66"], [layout="row"] > [flex="66"], [layout="row"] > [flex="66"] {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

[layout="column"] > [flex="33"], [layout="column"] > [flex="33"], [layout="column"] > [flex="33"], [layout="column"] > [flex="33"] {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

[layout="column"] > [flex="66"], [layout="column"] > [flex="66"], [layout="column"] > [flex="66"], [layout="column"] > [flex="66"] {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

[layout], [layout="column"], [layout="row"] {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

[layout="column"] {
  flex-direction: column; }

[layout="row"] {
  flex-direction: row; }

[layout-padding] > [flex-sm], [layout-padding] > [flex-lt-md] {
  padding: 5px; }

[layout-padding],
[layout-padding] > [flex],
[layout-padding] > [flex-gt-sm],
[layout-padding] > [flex-md],
[layout-padding] > [flex-lt-lg] {
  padding: 10px; }

[layout-padding] > [flex-gt-md],
[layout-padding] > [flex-lg] {
  padding: 20px; }

[layout-margin] > [flex-sm],
[layout-margin] > [flex-lt-md] {
  margin: 5px; }

[layout-margin],
[layout-margin] > [flex],
[layout-margin] > [flex-gt-sm],
[layout-margin] > [flex-md],
[layout-margin] > [flex-lt-lg] {
  margin: 10px; }

[layout-margin] > [flex-gt-md],
[layout-margin] > [flex-lg] {
  margin: 20px; }

[layout-wrap] {
  flex-wrap: wrap; }

[layout-nowrap] {
  flex-wrap: nowrap; }

[layout-fill] {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 567px) {
  [hide-xs]:not([show-xs]):not([show]), [hide]:not([show-xs]):not([show]) {
    display: none; }
  [flex-order-xs="-20"] {
    order: -20; }
  [flex-order-xs="-19"] {
    order: -19; }
  [flex-order-xs="-18"] {
    order: -18; }
  [flex-order-xs="-17"] {
    order: -17; }
  [flex-order-xs="-16"] {
    order: -16; }
  [flex-order-xs="-15"] {
    order: -15; }
  [flex-order-xs="-14"] {
    order: -14; }
  [flex-order-xs="-13"] {
    order: -13; }
  [flex-order-xs="-12"] {
    order: -12; }
  [flex-order-xs="-11"] {
    order: -11; }
  [flex-order-xs="-10"] {
    order: -10; }
  [flex-order-xs="-9"] {
    order: -9; }
  [flex-order-xs="-8"] {
    order: -8; }
  [flex-order-xs="-7"] {
    order: -7; }
  [flex-order-xs="-6"] {
    order: -6; }
  [flex-order-xs="-5"] {
    order: -5; }
  [flex-order-xs="-4"] {
    order: -4; }
  [flex-order-xs="-3"] {
    order: -3; }
  [flex-order-xs="-2"] {
    order: -2; }
  [flex-order-xs="-1"] {
    order: -1; }
  [flex-order-xs="0"] {
    order: 0; }
  [flex-order-xs="1"] {
    order: 1; }
  [flex-order-xs="2"] {
    order: 2; }
  [flex-order-xs="3"] {
    order: 3; }
  [flex-order-xs="4"] {
    order: 4; }
  [flex-order-xs="5"] {
    order: 5; }
  [flex-order-xs="6"] {
    order: 6; }
  [flex-order-xs="7"] {
    order: 7; }
  [flex-order-xs="8"] {
    order: 8; }
  [flex-order-xs="9"] {
    order: 9; }
  [flex-order-xs="10"] {
    order: 10; }
  [flex-order-xs="11"] {
    order: 11; }
  [flex-order-xs="12"] {
    order: 12; }
  [flex-order-xs="13"] {
    order: 13; }
  [flex-order-xs="14"] {
    order: 14; }
  [flex-order-xs="15"] {
    order: 15; }
  [flex-order-xs="16"] {
    order: 16; }
  [flex-order-xs="17"] {
    order: 17; }
  [flex-order-xs="18"] {
    order: 18; }
  [flex-order-xs="19"] {
    order: 19; }
  [flex-order-xs="20"] {
    order: 20; }
  [flex-offset-xs="0"] {
    margin-left: 0%; }
  [flex-offset-xs="5"] {
    margin-left: 5%; }
  [flex-offset-xs="10"] {
    margin-left: 10%; }
  [flex-offset-xs="15"] {
    margin-left: 15%; }
  [flex-offset-xs="20"] {
    margin-left: 20%; }
  [flex-offset-xs="25"] {
    margin-left: 25%; }
  [flex-offset-xs="30"] {
    margin-left: 30%; }
  [flex-offset-xs="35"] {
    margin-left: 35%; }
  [flex-offset-xs="40"] {
    margin-left: 40%; }
  [flex-offset-xs="45"] {
    margin-left: 45%; }
  [flex-offset-xs="50"] {
    margin-left: 50%; }
  [flex-offset-xs="55"] {
    margin-left: 55%; }
  [flex-offset-xs="60"] {
    margin-left: 60%; }
  [flex-offset-xs="65"] {
    margin-left: 65%; }
  [flex-offset-xs="70"] {
    margin-left: 70%; }
  [flex-offset-xs="75"] {
    margin-left: 75%; }
  [flex-offset-xs="80"] {
    margin-left: 80%; }
  [flex-offset-xs="85"] {
    margin-left: 85%; }
  [flex-offset-xs="90"] {
    margin-left: 90%; }
  [flex-offset-xs="95"] {
    margin-left: 95%; }
  [flex-offset-xs="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-xs="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-xs],
  [layout-align-xs="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-xs="start"],
  [layout-align-xs="start start"],
  [layout-align-xs="start center"],
  [layout-align-xs="start end"],
  [layout-align-xs="start stretch"] {
    justify-content: flex-start; }
  [layout-align-xs="center"],
  [layout-align-xs="center start"],
  [layout-align-xs="center center"],
  [layout-align-xs="center end"],
  [layout-align-xs="center stretch"] {
    justify-content: center; }
  [layout-align-xs="end"],
  [layout-align-xs="end center"],
  [layout-align-xs="end start"],
  [layout-align-xs="end end"],
  [layout-align-xs="end stretch"] {
    justify-content: flex-end; }
  [layout-align-xs="space-around"],
  [layout-align-xs="space-around center"],
  [layout-align-xs="space-around start"],
  [layout-align-xs="space-around end"],
  [layout-align-xs="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-xs="space-between"],
  [layout-align-xs="space-between center"],
  [layout-align-xs="space-between start"],
  [layout-align-xs="space-between end"],
  [layout-align-xs="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-xs="start start"],
  [layout-align-xs="center start"],
  [layout-align-xs="end start"],
  [layout-align-xs="space-between start"],
  [layout-align-xs="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-xs="start center"],
  [layout-align-xs="center center"],
  [layout-align-xs="end center"],
  [layout-align-xs="space-between center"],
  [layout-align-xs="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-xs="start center"] > *,
  [layout-align-xs="center center"] > *,
  [layout-align-xs="end center"] > *,
  [layout-align-xs="space-between center"] > *,
  [layout-align-xs="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-xs="start end"],
  [layout-align-xs="center end"],
  [layout-align-xs="end end"],
  [layout-align-xs="space-between end"],
  [layout-align-xs="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-xs="start stretch"],
  [layout-align-xs="center stretch"],
  [layout-align-xs="end stretch"],
  [layout-align-xs="space-between stretch"],
  [layout-align-xs="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-xs] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (max-width: 567px) {
  [flex-xs] {
    flex: 1 1 0%; } }

@media (max-width: 567px) {
  [flex-xs-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-xs-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-xs-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-xs-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="0"],
  [layout-xs="row"] > [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="0"],
  [layout-xs="column"] > [flex-xs="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="5"],
  [layout-xs="row"] > [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="5"],
  [layout-xs="column"] > [flex-xs="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="10"],
  [layout-xs="row"] > [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="10"],
  [layout-xs="column"] > [flex-xs="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="15"],
  [layout-xs="row"] > [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="15"],
  [layout-xs="column"] > [flex-xs="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="20"],
  [layout-xs="row"] > [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="20"],
  [layout-xs="column"] > [flex-xs="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="25"],
  [layout-xs="row"] > [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="25"],
  [layout-xs="column"] > [flex-xs="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="30"],
  [layout-xs="row"] > [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="30"],
  [layout-xs="column"] > [flex-xs="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="35"],
  [layout-xs="row"] > [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="35"],
  [layout-xs="column"] > [flex-xs="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="40"],
  [layout-xs="row"] > [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="40"],
  [layout-xs="column"] > [flex-xs="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="45"],
  [layout-xs="row"] > [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="45"],
  [layout-xs="column"] > [flex-xs="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="50"],
  [layout-xs="row"] > [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="50"],
  [layout-xs="column"] > [flex-xs="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="55"],
  [layout-xs="row"] > [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="55"],
  [layout-xs="column"] > [flex-xs="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="60"],
  [layout-xs="row"] > [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="60"],
  [layout-xs="column"] > [flex-xs="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="65"],
  [layout-xs="row"] > [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="65"],
  [layout-xs="column"] > [flex-xs="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="70"],
  [layout-xs="row"] > [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="70"],
  [layout-xs="column"] > [flex-xs="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="75"],
  [layout-xs="row"] > [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="75"],
  [layout-xs="column"] > [flex-xs="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="80"],
  [layout-xs="row"] > [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="80"],
  [layout-xs="column"] > [flex-xs="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="85"],
  [layout-xs="row"] > [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="85"],
  [layout-xs="column"] > [flex-xs="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="90"],
  [layout-xs="row"] > [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="90"],
  [layout-xs="column"] > [flex-xs="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="95"],
  [layout-xs="row"] > [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="95"],
  [layout-xs="column"] > [flex-xs="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="100"],
  [layout-xs="row"] > [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="100"],
  [layout-xs="column"] > [flex-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="33"], [layout="row"] > [flex-xs="33"], [layout-xs="row"] > [flex-xs="33"], [layout-xs="row"] > [flex-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xs="66"], [layout="row"] > [flex-xs="66"], [layout-xs="row"] > [flex-xs="66"], [layout-xs="row"] > [flex-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="33"], [layout="column"] > [flex-xs="33"], [layout-xs="column"] > [flex-xs="33"], [layout-xs="column"] > [flex-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xs="66"], [layout="column"] > [flex-xs="66"], [layout-xs="column"] > [flex-xs="66"], [layout-xs="column"] > [flex-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-xs], [layout-xs="column"], [layout-xs="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-xs="column"] {
    flex-direction: column; }
  [layout-xs="row"] {
    flex-direction: row; } }

@media (min-width: 568px) {
  [flex-order-gt-xs="-20"] {
    order: -20; }
  [flex-order-gt-xs="-19"] {
    order: -19; }
  [flex-order-gt-xs="-18"] {
    order: -18; }
  [flex-order-gt-xs="-17"] {
    order: -17; }
  [flex-order-gt-xs="-16"] {
    order: -16; }
  [flex-order-gt-xs="-15"] {
    order: -15; }
  [flex-order-gt-xs="-14"] {
    order: -14; }
  [flex-order-gt-xs="-13"] {
    order: -13; }
  [flex-order-gt-xs="-12"] {
    order: -12; }
  [flex-order-gt-xs="-11"] {
    order: -11; }
  [flex-order-gt-xs="-10"] {
    order: -10; }
  [flex-order-gt-xs="-9"] {
    order: -9; }
  [flex-order-gt-xs="-8"] {
    order: -8; }
  [flex-order-gt-xs="-7"] {
    order: -7; }
  [flex-order-gt-xs="-6"] {
    order: -6; }
  [flex-order-gt-xs="-5"] {
    order: -5; }
  [flex-order-gt-xs="-4"] {
    order: -4; }
  [flex-order-gt-xs="-3"] {
    order: -3; }
  [flex-order-gt-xs="-2"] {
    order: -2; }
  [flex-order-gt-xs="-1"] {
    order: -1; }
  [flex-order-gt-xs="0"] {
    order: 0; }
  [flex-order-gt-xs="1"] {
    order: 1; }
  [flex-order-gt-xs="2"] {
    order: 2; }
  [flex-order-gt-xs="3"] {
    order: 3; }
  [flex-order-gt-xs="4"] {
    order: 4; }
  [flex-order-gt-xs="5"] {
    order: 5; }
  [flex-order-gt-xs="6"] {
    order: 6; }
  [flex-order-gt-xs="7"] {
    order: 7; }
  [flex-order-gt-xs="8"] {
    order: 8; }
  [flex-order-gt-xs="9"] {
    order: 9; }
  [flex-order-gt-xs="10"] {
    order: 10; }
  [flex-order-gt-xs="11"] {
    order: 11; }
  [flex-order-gt-xs="12"] {
    order: 12; }
  [flex-order-gt-xs="13"] {
    order: 13; }
  [flex-order-gt-xs="14"] {
    order: 14; }
  [flex-order-gt-xs="15"] {
    order: 15; }
  [flex-order-gt-xs="16"] {
    order: 16; }
  [flex-order-gt-xs="17"] {
    order: 17; }
  [flex-order-gt-xs="18"] {
    order: 18; }
  [flex-order-gt-xs="19"] {
    order: 19; }
  [flex-order-gt-xs="20"] {
    order: 20; }
  [flex-offset-gt-xs="0"] {
    margin-left: 0%; }
  [flex-offset-gt-xs="5"] {
    margin-left: 5%; }
  [flex-offset-gt-xs="10"] {
    margin-left: 10%; }
  [flex-offset-gt-xs="15"] {
    margin-left: 15%; }
  [flex-offset-gt-xs="20"] {
    margin-left: 20%; }
  [flex-offset-gt-xs="25"] {
    margin-left: 25%; }
  [flex-offset-gt-xs="30"] {
    margin-left: 30%; }
  [flex-offset-gt-xs="35"] {
    margin-left: 35%; }
  [flex-offset-gt-xs="40"] {
    margin-left: 40%; }
  [flex-offset-gt-xs="45"] {
    margin-left: 45%; }
  [flex-offset-gt-xs="50"] {
    margin-left: 50%; }
  [flex-offset-gt-xs="55"] {
    margin-left: 55%; }
  [flex-offset-gt-xs="60"] {
    margin-left: 60%; }
  [flex-offset-gt-xs="65"] {
    margin-left: 65%; }
  [flex-offset-gt-xs="70"] {
    margin-left: 70%; }
  [flex-offset-gt-xs="75"] {
    margin-left: 75%; }
  [flex-offset-gt-xs="80"] {
    margin-left: 80%; }
  [flex-offset-gt-xs="85"] {
    margin-left: 85%; }
  [flex-offset-gt-xs="90"] {
    margin-left: 90%; }
  [flex-offset-gt-xs="95"] {
    margin-left: 95%; }
  [flex-offset-gt-xs="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-xs="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-xs],
  [layout-align-gt-xs="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-xs="start"],
  [layout-align-gt-xs="start start"],
  [layout-align-gt-xs="start center"],
  [layout-align-gt-xs="start end"],
  [layout-align-gt-xs="start stretch"] {
    justify-content: flex-start; }
  [layout-align-gt-xs="center"],
  [layout-align-gt-xs="center start"],
  [layout-align-gt-xs="center center"],
  [layout-align-gt-xs="center end"],
  [layout-align-gt-xs="center stretch"] {
    justify-content: center; }
  [layout-align-gt-xs="end"],
  [layout-align-gt-xs="end center"],
  [layout-align-gt-xs="end start"],
  [layout-align-gt-xs="end end"],
  [layout-align-gt-xs="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-xs="space-around"],
  [layout-align-gt-xs="space-around center"],
  [layout-align-gt-xs="space-around start"],
  [layout-align-gt-xs="space-around end"],
  [layout-align-gt-xs="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-xs="space-between"],
  [layout-align-gt-xs="space-between center"],
  [layout-align-gt-xs="space-between start"],
  [layout-align-gt-xs="space-between end"],
  [layout-align-gt-xs="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-xs="start start"],
  [layout-align-gt-xs="center start"],
  [layout-align-gt-xs="end start"],
  [layout-align-gt-xs="space-between start"],
  [layout-align-gt-xs="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-xs="start center"],
  [layout-align-gt-xs="center center"],
  [layout-align-gt-xs="end center"],
  [layout-align-gt-xs="space-between center"],
  [layout-align-gt-xs="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-xs="start center"] > *,
  [layout-align-gt-xs="center center"] > *,
  [layout-align-gt-xs="end center"] > *,
  [layout-align-gt-xs="space-between center"] > *,
  [layout-align-gt-xs="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-xs="start end"],
  [layout-align-gt-xs="center end"],
  [layout-align-gt-xs="end end"],
  [layout-align-gt-xs="space-between end"],
  [layout-align-gt-xs="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-xs="start stretch"],
  [layout-align-gt-xs="center stretch"],
  [layout-align-gt-xs="end stretch"],
  [layout-align-gt-xs="space-between stretch"],
  [layout-align-gt-xs="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-xs] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 568px) {
  [flex-gt-xs] {
    flex: 1 1 0%; } }

@media (min-width: 568px) {
  [flex-gt-xs-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-xs-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-xs-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-xs-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="0"],
  [layout-gt-xs="row"] > [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="0"],
  [layout-gt-xs="column"] > [flex-gt-xs="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="5"],
  [layout-gt-xs="row"] > [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="5"],
  [layout-gt-xs="column"] > [flex-gt-xs="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="10"],
  [layout-gt-xs="row"] > [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="10"],
  [layout-gt-xs="column"] > [flex-gt-xs="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="15"],
  [layout-gt-xs="row"] > [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="15"],
  [layout-gt-xs="column"] > [flex-gt-xs="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="20"],
  [layout-gt-xs="row"] > [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="20"],
  [layout-gt-xs="column"] > [flex-gt-xs="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="25"],
  [layout-gt-xs="row"] > [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="25"],
  [layout-gt-xs="column"] > [flex-gt-xs="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="30"],
  [layout-gt-xs="row"] > [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="30"],
  [layout-gt-xs="column"] > [flex-gt-xs="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="35"],
  [layout-gt-xs="row"] > [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="35"],
  [layout-gt-xs="column"] > [flex-gt-xs="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="40"],
  [layout-gt-xs="row"] > [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="40"],
  [layout-gt-xs="column"] > [flex-gt-xs="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="45"],
  [layout-gt-xs="row"] > [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="45"],
  [layout-gt-xs="column"] > [flex-gt-xs="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="50"],
  [layout-gt-xs="row"] > [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="50"],
  [layout-gt-xs="column"] > [flex-gt-xs="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="55"],
  [layout-gt-xs="row"] > [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="55"],
  [layout-gt-xs="column"] > [flex-gt-xs="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="60"],
  [layout-gt-xs="row"] > [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="60"],
  [layout-gt-xs="column"] > [flex-gt-xs="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="65"],
  [layout-gt-xs="row"] > [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="65"],
  [layout-gt-xs="column"] > [flex-gt-xs="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="70"],
  [layout-gt-xs="row"] > [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="70"],
  [layout-gt-xs="column"] > [flex-gt-xs="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="75"],
  [layout-gt-xs="row"] > [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="75"],
  [layout-gt-xs="column"] > [flex-gt-xs="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="80"],
  [layout-gt-xs="row"] > [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="80"],
  [layout-gt-xs="column"] > [flex-gt-xs="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="85"],
  [layout-gt-xs="row"] > [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="85"],
  [layout-gt-xs="column"] > [flex-gt-xs="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="90"],
  [layout-gt-xs="row"] > [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="90"],
  [layout-gt-xs="column"] > [flex-gt-xs="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="95"],
  [layout-gt-xs="row"] > [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="95"],
  [layout-gt-xs="column"] > [flex-gt-xs="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="100"],
  [layout-gt-xs="row"] > [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="100"],
  [layout-gt-xs="column"] > [flex-gt-xs="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="33"], [layout="row"] > [flex-gt-xs="33"], [layout-gt-xs="row"] > [flex-gt-xs="33"], [layout-gt-xs="row"] > [flex-gt-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-xs="66"], [layout="row"] > [flex-gt-xs="66"], [layout-gt-xs="row"] > [flex-gt-xs="66"], [layout-gt-xs="row"] > [flex-gt-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="33"], [layout="column"] > [flex-gt-xs="33"], [layout-gt-xs="column"] > [flex-gt-xs="33"], [layout-gt-xs="column"] > [flex-gt-xs="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-xs="66"], [layout="column"] > [flex-gt-xs="66"], [layout-gt-xs="column"] > [flex-gt-xs="66"], [layout-gt-xs="column"] > [flex-gt-xs="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-gt-xs], [layout-gt-xs="column"], [layout-gt-xs="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-xs="column"] {
    flex-direction: column; }
  [layout-gt-xs="row"] {
    flex-direction: row; } }

@media (min-width: 568px) and (max-width: 767px) {
  [hide-sm]:not([show-gt-xs]):not([show-sm]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-sm]):not([show]) {
    display: none; }
  [hide-sm]:not([show-sm]):not([show]) {
    display: none; }
  [flex-order-sm="-20"] {
    order: -20; }
  [flex-order-sm="-19"] {
    order: -19; }
  [flex-order-sm="-18"] {
    order: -18; }
  [flex-order-sm="-17"] {
    order: -17; }
  [flex-order-sm="-16"] {
    order: -16; }
  [flex-order-sm="-15"] {
    order: -15; }
  [flex-order-sm="-14"] {
    order: -14; }
  [flex-order-sm="-13"] {
    order: -13; }
  [flex-order-sm="-12"] {
    order: -12; }
  [flex-order-sm="-11"] {
    order: -11; }
  [flex-order-sm="-10"] {
    order: -10; }
  [flex-order-sm="-9"] {
    order: -9; }
  [flex-order-sm="-8"] {
    order: -8; }
  [flex-order-sm="-7"] {
    order: -7; }
  [flex-order-sm="-6"] {
    order: -6; }
  [flex-order-sm="-5"] {
    order: -5; }
  [flex-order-sm="-4"] {
    order: -4; }
  [flex-order-sm="-3"] {
    order: -3; }
  [flex-order-sm="-2"] {
    order: -2; }
  [flex-order-sm="-1"] {
    order: -1; }
  [flex-order-sm="0"] {
    order: 0; }
  [flex-order-sm="1"] {
    order: 1; }
  [flex-order-sm="2"] {
    order: 2; }
  [flex-order-sm="3"] {
    order: 3; }
  [flex-order-sm="4"] {
    order: 4; }
  [flex-order-sm="5"] {
    order: 5; }
  [flex-order-sm="6"] {
    order: 6; }
  [flex-order-sm="7"] {
    order: 7; }
  [flex-order-sm="8"] {
    order: 8; }
  [flex-order-sm="9"] {
    order: 9; }
  [flex-order-sm="10"] {
    order: 10; }
  [flex-order-sm="11"] {
    order: 11; }
  [flex-order-sm="12"] {
    order: 12; }
  [flex-order-sm="13"] {
    order: 13; }
  [flex-order-sm="14"] {
    order: 14; }
  [flex-order-sm="15"] {
    order: 15; }
  [flex-order-sm="16"] {
    order: 16; }
  [flex-order-sm="17"] {
    order: 17; }
  [flex-order-sm="18"] {
    order: 18; }
  [flex-order-sm="19"] {
    order: 19; }
  [flex-order-sm="20"] {
    order: 20; }
  [flex-offset-sm="0"] {
    margin-left: 0%; }
  [flex-offset-sm="5"] {
    margin-left: 5%; }
  [flex-offset-sm="10"] {
    margin-left: 10%; }
  [flex-offset-sm="15"] {
    margin-left: 15%; }
  [flex-offset-sm="20"] {
    margin-left: 20%; }
  [flex-offset-sm="25"] {
    margin-left: 25%; }
  [flex-offset-sm="30"] {
    margin-left: 30%; }
  [flex-offset-sm="35"] {
    margin-left: 35%; }
  [flex-offset-sm="40"] {
    margin-left: 40%; }
  [flex-offset-sm="45"] {
    margin-left: 45%; }
  [flex-offset-sm="50"] {
    margin-left: 50%; }
  [flex-offset-sm="55"] {
    margin-left: 55%; }
  [flex-offset-sm="60"] {
    margin-left: 60%; }
  [flex-offset-sm="65"] {
    margin-left: 65%; }
  [flex-offset-sm="70"] {
    margin-left: 70%; }
  [flex-offset-sm="75"] {
    margin-left: 75%; }
  [flex-offset-sm="80"] {
    margin-left: 80%; }
  [flex-offset-sm="85"] {
    margin-left: 85%; }
  [flex-offset-sm="90"] {
    margin-left: 90%; }
  [flex-offset-sm="95"] {
    margin-left: 95%; }
  [flex-offset-sm="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-sm="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-sm],
  [layout-align-sm="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-sm="start"],
  [layout-align-sm="start start"],
  [layout-align-sm="start center"],
  [layout-align-sm="start end"],
  [layout-align-sm="start stretch"] {
    justify-content: flex-start; }
  [layout-align-sm="center"],
  [layout-align-sm="center start"],
  [layout-align-sm="center center"],
  [layout-align-sm="center end"],
  [layout-align-sm="center stretch"] {
    justify-content: center; }
  [layout-align-sm="end"],
  [layout-align-sm="end center"],
  [layout-align-sm="end start"],
  [layout-align-sm="end end"],
  [layout-align-sm="end stretch"] {
    justify-content: flex-end; }
  [layout-align-sm="space-around"],
  [layout-align-sm="space-around center"],
  [layout-align-sm="space-around start"],
  [layout-align-sm="space-around end"],
  [layout-align-sm="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-sm="space-between"],
  [layout-align-sm="space-between center"],
  [layout-align-sm="space-between start"],
  [layout-align-sm="space-between end"],
  [layout-align-sm="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-sm="start start"],
  [layout-align-sm="center start"],
  [layout-align-sm="end start"],
  [layout-align-sm="space-between start"],
  [layout-align-sm="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-sm="start center"],
  [layout-align-sm="center center"],
  [layout-align-sm="end center"],
  [layout-align-sm="space-between center"],
  [layout-align-sm="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-sm="start center"] > *,
  [layout-align-sm="center center"] > *,
  [layout-align-sm="end center"] > *,
  [layout-align-sm="space-between center"] > *,
  [layout-align-sm="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-sm="start end"],
  [layout-align-sm="center end"],
  [layout-align-sm="end end"],
  [layout-align-sm="space-between end"],
  [layout-align-sm="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-sm="start stretch"],
  [layout-align-sm="center stretch"],
  [layout-align-sm="end stretch"],
  [layout-align-sm="space-between stretch"],
  [layout-align-sm="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-sm] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 568px) and (max-width: 767px) {
  [flex-sm] {
    flex: 1 1 0%; } }

@media (min-width: 568px) and (max-width: 767px) {
  [flex-sm-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-sm-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-sm-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-sm-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="0"],
  [layout-sm="row"] > [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="0"],
  [layout-sm="column"] > [flex-sm="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="5"],
  [layout-sm="row"] > [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="5"],
  [layout-sm="column"] > [flex-sm="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="10"],
  [layout-sm="row"] > [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="10"],
  [layout-sm="column"] > [flex-sm="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="15"],
  [layout-sm="row"] > [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="15"],
  [layout-sm="column"] > [flex-sm="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="20"],
  [layout-sm="row"] > [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="20"],
  [layout-sm="column"] > [flex-sm="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="25"],
  [layout-sm="row"] > [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="25"],
  [layout-sm="column"] > [flex-sm="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="30"],
  [layout-sm="row"] > [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="30"],
  [layout-sm="column"] > [flex-sm="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="35"],
  [layout-sm="row"] > [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="35"],
  [layout-sm="column"] > [flex-sm="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="40"],
  [layout-sm="row"] > [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="40"],
  [layout-sm="column"] > [flex-sm="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="45"],
  [layout-sm="row"] > [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="45"],
  [layout-sm="column"] > [flex-sm="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="50"],
  [layout-sm="row"] > [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="50"],
  [layout-sm="column"] > [flex-sm="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="55"],
  [layout-sm="row"] > [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="55"],
  [layout-sm="column"] > [flex-sm="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="60"],
  [layout-sm="row"] > [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="60"],
  [layout-sm="column"] > [flex-sm="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="65"],
  [layout-sm="row"] > [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="65"],
  [layout-sm="column"] > [flex-sm="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="70"],
  [layout-sm="row"] > [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="70"],
  [layout-sm="column"] > [flex-sm="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="75"],
  [layout-sm="row"] > [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="75"],
  [layout-sm="column"] > [flex-sm="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="80"],
  [layout-sm="row"] > [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="80"],
  [layout-sm="column"] > [flex-sm="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="85"],
  [layout-sm="row"] > [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="85"],
  [layout-sm="column"] > [flex-sm="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="90"],
  [layout-sm="row"] > [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="90"],
  [layout-sm="column"] > [flex-sm="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="95"],
  [layout-sm="row"] > [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="95"],
  [layout-sm="column"] > [flex-sm="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="100"],
  [layout-sm="row"] > [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="100"],
  [layout-sm="column"] > [flex-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="33"], [layout="row"] > [flex-sm="33"], [layout-sm="row"] > [flex-sm="33"], [layout-sm="row"] > [flex-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-sm="66"], [layout="row"] > [flex-sm="66"], [layout-sm="row"] > [flex-sm="66"], [layout-sm="row"] > [flex-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="33"], [layout="column"] > [flex-sm="33"], [layout-sm="column"] > [flex-sm="33"], [layout-sm="column"] > [flex-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-sm="66"], [layout="column"] > [flex-sm="66"], [layout-sm="column"] > [flex-sm="66"], [layout-sm="column"] > [flex-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-sm], [layout-sm="column"], [layout-sm="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-sm="column"] {
    flex-direction: column; }
  [layout-sm="row"] {
    flex-direction: row; } }

@media (min-width: 768px) {
  [flex-order-gt-sm="-20"] {
    order: -20; }
  [flex-order-gt-sm="-19"] {
    order: -19; }
  [flex-order-gt-sm="-18"] {
    order: -18; }
  [flex-order-gt-sm="-17"] {
    order: -17; }
  [flex-order-gt-sm="-16"] {
    order: -16; }
  [flex-order-gt-sm="-15"] {
    order: -15; }
  [flex-order-gt-sm="-14"] {
    order: -14; }
  [flex-order-gt-sm="-13"] {
    order: -13; }
  [flex-order-gt-sm="-12"] {
    order: -12; }
  [flex-order-gt-sm="-11"] {
    order: -11; }
  [flex-order-gt-sm="-10"] {
    order: -10; }
  [flex-order-gt-sm="-9"] {
    order: -9; }
  [flex-order-gt-sm="-8"] {
    order: -8; }
  [flex-order-gt-sm="-7"] {
    order: -7; }
  [flex-order-gt-sm="-6"] {
    order: -6; }
  [flex-order-gt-sm="-5"] {
    order: -5; }
  [flex-order-gt-sm="-4"] {
    order: -4; }
  [flex-order-gt-sm="-3"] {
    order: -3; }
  [flex-order-gt-sm="-2"] {
    order: -2; }
  [flex-order-gt-sm="-1"] {
    order: -1; }
  [flex-order-gt-sm="0"] {
    order: 0; }
  [flex-order-gt-sm="1"] {
    order: 1; }
  [flex-order-gt-sm="2"] {
    order: 2; }
  [flex-order-gt-sm="3"] {
    order: 3; }
  [flex-order-gt-sm="4"] {
    order: 4; }
  [flex-order-gt-sm="5"] {
    order: 5; }
  [flex-order-gt-sm="6"] {
    order: 6; }
  [flex-order-gt-sm="7"] {
    order: 7; }
  [flex-order-gt-sm="8"] {
    order: 8; }
  [flex-order-gt-sm="9"] {
    order: 9; }
  [flex-order-gt-sm="10"] {
    order: 10; }
  [flex-order-gt-sm="11"] {
    order: 11; }
  [flex-order-gt-sm="12"] {
    order: 12; }
  [flex-order-gt-sm="13"] {
    order: 13; }
  [flex-order-gt-sm="14"] {
    order: 14; }
  [flex-order-gt-sm="15"] {
    order: 15; }
  [flex-order-gt-sm="16"] {
    order: 16; }
  [flex-order-gt-sm="17"] {
    order: 17; }
  [flex-order-gt-sm="18"] {
    order: 18; }
  [flex-order-gt-sm="19"] {
    order: 19; }
  [flex-order-gt-sm="20"] {
    order: 20; }
  [flex-offset-gt-sm="0"] {
    margin-left: 0%; }
  [flex-offset-gt-sm="5"] {
    margin-left: 5%; }
  [flex-offset-gt-sm="10"] {
    margin-left: 10%; }
  [flex-offset-gt-sm="15"] {
    margin-left: 15%; }
  [flex-offset-gt-sm="20"] {
    margin-left: 20%; }
  [flex-offset-gt-sm="25"] {
    margin-left: 25%; }
  [flex-offset-gt-sm="30"] {
    margin-left: 30%; }
  [flex-offset-gt-sm="35"] {
    margin-left: 35%; }
  [flex-offset-gt-sm="40"] {
    margin-left: 40%; }
  [flex-offset-gt-sm="45"] {
    margin-left: 45%; }
  [flex-offset-gt-sm="50"] {
    margin-left: 50%; }
  [flex-offset-gt-sm="55"] {
    margin-left: 55%; }
  [flex-offset-gt-sm="60"] {
    margin-left: 60%; }
  [flex-offset-gt-sm="65"] {
    margin-left: 65%; }
  [flex-offset-gt-sm="70"] {
    margin-left: 70%; }
  [flex-offset-gt-sm="75"] {
    margin-left: 75%; }
  [flex-offset-gt-sm="80"] {
    margin-left: 80%; }
  [flex-offset-gt-sm="85"] {
    margin-left: 85%; }
  [flex-offset-gt-sm="90"] {
    margin-left: 90%; }
  [flex-offset-gt-sm="95"] {
    margin-left: 95%; }
  [flex-offset-gt-sm="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-sm="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-sm],
  [layout-align-gt-sm="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-sm="start"],
  [layout-align-gt-sm="start start"],
  [layout-align-gt-sm="start center"],
  [layout-align-gt-sm="start end"],
  [layout-align-gt-sm="start stretch"] {
    justify-content: flex-start; }
  [layout-align-gt-sm="center"],
  [layout-align-gt-sm="center start"],
  [layout-align-gt-sm="center center"],
  [layout-align-gt-sm="center end"],
  [layout-align-gt-sm="center stretch"] {
    justify-content: center; }
  [layout-align-gt-sm="end"],
  [layout-align-gt-sm="end center"],
  [layout-align-gt-sm="end start"],
  [layout-align-gt-sm="end end"],
  [layout-align-gt-sm="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-sm="space-around"],
  [layout-align-gt-sm="space-around center"],
  [layout-align-gt-sm="space-around start"],
  [layout-align-gt-sm="space-around end"],
  [layout-align-gt-sm="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-sm="space-between"],
  [layout-align-gt-sm="space-between center"],
  [layout-align-gt-sm="space-between start"],
  [layout-align-gt-sm="space-between end"],
  [layout-align-gt-sm="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-sm="start start"],
  [layout-align-gt-sm="center start"],
  [layout-align-gt-sm="end start"],
  [layout-align-gt-sm="space-between start"],
  [layout-align-gt-sm="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-sm="start center"],
  [layout-align-gt-sm="center center"],
  [layout-align-gt-sm="end center"],
  [layout-align-gt-sm="space-between center"],
  [layout-align-gt-sm="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-sm="start center"] > *,
  [layout-align-gt-sm="center center"] > *,
  [layout-align-gt-sm="end center"] > *,
  [layout-align-gt-sm="space-between center"] > *,
  [layout-align-gt-sm="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-sm="start end"],
  [layout-align-gt-sm="center end"],
  [layout-align-gt-sm="end end"],
  [layout-align-gt-sm="space-between end"],
  [layout-align-gt-sm="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-sm="start stretch"],
  [layout-align-gt-sm="center stretch"],
  [layout-align-gt-sm="end stretch"],
  [layout-align-gt-sm="space-between stretch"],
  [layout-align-gt-sm="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-sm] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 768px) {
  [flex-gt-sm] {
    flex: 1 1 0%; } }

@media (min-width: 768px) {
  [flex-gt-sm-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-sm-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-sm-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-sm-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="0"],
  [layout-gt-sm="row"] > [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="0"],
  [layout-gt-sm="column"] > [flex-gt-sm="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="5"],
  [layout-gt-sm="row"] > [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="5"],
  [layout-gt-sm="column"] > [flex-gt-sm="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="10"],
  [layout-gt-sm="row"] > [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="10"],
  [layout-gt-sm="column"] > [flex-gt-sm="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="15"],
  [layout-gt-sm="row"] > [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="15"],
  [layout-gt-sm="column"] > [flex-gt-sm="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="20"],
  [layout-gt-sm="row"] > [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="20"],
  [layout-gt-sm="column"] > [flex-gt-sm="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="25"],
  [layout-gt-sm="row"] > [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="25"],
  [layout-gt-sm="column"] > [flex-gt-sm="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="30"],
  [layout-gt-sm="row"] > [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="30"],
  [layout-gt-sm="column"] > [flex-gt-sm="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="35"],
  [layout-gt-sm="row"] > [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="35"],
  [layout-gt-sm="column"] > [flex-gt-sm="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="40"],
  [layout-gt-sm="row"] > [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="40"],
  [layout-gt-sm="column"] > [flex-gt-sm="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="45"],
  [layout-gt-sm="row"] > [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="45"],
  [layout-gt-sm="column"] > [flex-gt-sm="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="50"],
  [layout-gt-sm="row"] > [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="50"],
  [layout-gt-sm="column"] > [flex-gt-sm="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="55"],
  [layout-gt-sm="row"] > [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="55"],
  [layout-gt-sm="column"] > [flex-gt-sm="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="60"],
  [layout-gt-sm="row"] > [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="60"],
  [layout-gt-sm="column"] > [flex-gt-sm="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="65"],
  [layout-gt-sm="row"] > [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="65"],
  [layout-gt-sm="column"] > [flex-gt-sm="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="70"],
  [layout-gt-sm="row"] > [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="70"],
  [layout-gt-sm="column"] > [flex-gt-sm="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="75"],
  [layout-gt-sm="row"] > [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="75"],
  [layout-gt-sm="column"] > [flex-gt-sm="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="80"],
  [layout-gt-sm="row"] > [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="80"],
  [layout-gt-sm="column"] > [flex-gt-sm="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="85"],
  [layout-gt-sm="row"] > [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="85"],
  [layout-gt-sm="column"] > [flex-gt-sm="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="90"],
  [layout-gt-sm="row"] > [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="90"],
  [layout-gt-sm="column"] > [flex-gt-sm="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="95"],
  [layout-gt-sm="row"] > [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="95"],
  [layout-gt-sm="column"] > [flex-gt-sm="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="100"],
  [layout-gt-sm="row"] > [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="100"],
  [layout-gt-sm="column"] > [flex-gt-sm="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="33"], [layout="row"] > [flex-gt-sm="33"], [layout-gt-sm="row"] > [flex-gt-sm="33"], [layout-gt-sm="row"] > [flex-gt-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-sm="66"], [layout="row"] > [flex-gt-sm="66"], [layout-gt-sm="row"] > [flex-gt-sm="66"], [layout-gt-sm="row"] > [flex-gt-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="33"], [layout="column"] > [flex-gt-sm="33"], [layout-gt-sm="column"] > [flex-gt-sm="33"], [layout-gt-sm="column"] > [flex-gt-sm="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-sm="66"], [layout="column"] > [flex-gt-sm="66"], [layout-gt-sm="column"] > [flex-gt-sm="66"], [layout-gt-sm="column"] > [flex-gt-sm="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-gt-sm], [layout-gt-sm="column"], [layout-gt-sm="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-sm="column"] {
    flex-direction: column; }
  [layout-gt-sm="row"] {
    flex-direction: row; } }

@media (min-width: 768px) and (max-width: 1279px) {
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-md]):not([show]) {
    display: none; }
  [hide-md]:not([show-md]):not([show]) {
    display: none; }
  [flex-order-md="-20"] {
    order: -20; }
  [flex-order-md="-19"] {
    order: -19; }
  [flex-order-md="-18"] {
    order: -18; }
  [flex-order-md="-17"] {
    order: -17; }
  [flex-order-md="-16"] {
    order: -16; }
  [flex-order-md="-15"] {
    order: -15; }
  [flex-order-md="-14"] {
    order: -14; }
  [flex-order-md="-13"] {
    order: -13; }
  [flex-order-md="-12"] {
    order: -12; }
  [flex-order-md="-11"] {
    order: -11; }
  [flex-order-md="-10"] {
    order: -10; }
  [flex-order-md="-9"] {
    order: -9; }
  [flex-order-md="-8"] {
    order: -8; }
  [flex-order-md="-7"] {
    order: -7; }
  [flex-order-md="-6"] {
    order: -6; }
  [flex-order-md="-5"] {
    order: -5; }
  [flex-order-md="-4"] {
    order: -4; }
  [flex-order-md="-3"] {
    order: -3; }
  [flex-order-md="-2"] {
    order: -2; }
  [flex-order-md="-1"] {
    order: -1; }
  [flex-order-md="0"] {
    order: 0; }
  [flex-order-md="1"] {
    order: 1; }
  [flex-order-md="2"] {
    order: 2; }
  [flex-order-md="3"] {
    order: 3; }
  [flex-order-md="4"] {
    order: 4; }
  [flex-order-md="5"] {
    order: 5; }
  [flex-order-md="6"] {
    order: 6; }
  [flex-order-md="7"] {
    order: 7; }
  [flex-order-md="8"] {
    order: 8; }
  [flex-order-md="9"] {
    order: 9; }
  [flex-order-md="10"] {
    order: 10; }
  [flex-order-md="11"] {
    order: 11; }
  [flex-order-md="12"] {
    order: 12; }
  [flex-order-md="13"] {
    order: 13; }
  [flex-order-md="14"] {
    order: 14; }
  [flex-order-md="15"] {
    order: 15; }
  [flex-order-md="16"] {
    order: 16; }
  [flex-order-md="17"] {
    order: 17; }
  [flex-order-md="18"] {
    order: 18; }
  [flex-order-md="19"] {
    order: 19; }
  [flex-order-md="20"] {
    order: 20; }
  [flex-offset-md="0"] {
    margin-left: 0%; }
  [flex-offset-md="5"] {
    margin-left: 5%; }
  [flex-offset-md="10"] {
    margin-left: 10%; }
  [flex-offset-md="15"] {
    margin-left: 15%; }
  [flex-offset-md="20"] {
    margin-left: 20%; }
  [flex-offset-md="25"] {
    margin-left: 25%; }
  [flex-offset-md="30"] {
    margin-left: 30%; }
  [flex-offset-md="35"] {
    margin-left: 35%; }
  [flex-offset-md="40"] {
    margin-left: 40%; }
  [flex-offset-md="45"] {
    margin-left: 45%; }
  [flex-offset-md="50"] {
    margin-left: 50%; }
  [flex-offset-md="55"] {
    margin-left: 55%; }
  [flex-offset-md="60"] {
    margin-left: 60%; }
  [flex-offset-md="65"] {
    margin-left: 65%; }
  [flex-offset-md="70"] {
    margin-left: 70%; }
  [flex-offset-md="75"] {
    margin-left: 75%; }
  [flex-offset-md="80"] {
    margin-left: 80%; }
  [flex-offset-md="85"] {
    margin-left: 85%; }
  [flex-offset-md="90"] {
    margin-left: 90%; }
  [flex-offset-md="95"] {
    margin-left: 95%; }
  [flex-offset-md="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-md="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-md],
  [layout-align-md="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-md="start"],
  [layout-align-md="start start"],
  [layout-align-md="start center"],
  [layout-align-md="start end"],
  [layout-align-md="start stretch"] {
    justify-content: flex-start; }
  [layout-align-md="center"],
  [layout-align-md="center start"],
  [layout-align-md="center center"],
  [layout-align-md="center end"],
  [layout-align-md="center stretch"] {
    justify-content: center; }
  [layout-align-md="end"],
  [layout-align-md="end center"],
  [layout-align-md="end start"],
  [layout-align-md="end end"],
  [layout-align-md="end stretch"] {
    justify-content: flex-end; }
  [layout-align-md="space-around"],
  [layout-align-md="space-around center"],
  [layout-align-md="space-around start"],
  [layout-align-md="space-around end"],
  [layout-align-md="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-md="space-between"],
  [layout-align-md="space-between center"],
  [layout-align-md="space-between start"],
  [layout-align-md="space-between end"],
  [layout-align-md="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-md="start start"],
  [layout-align-md="center start"],
  [layout-align-md="end start"],
  [layout-align-md="space-between start"],
  [layout-align-md="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-md="start center"],
  [layout-align-md="center center"],
  [layout-align-md="end center"],
  [layout-align-md="space-between center"],
  [layout-align-md="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-md="start center"] > *,
  [layout-align-md="center center"] > *,
  [layout-align-md="end center"] > *,
  [layout-align-md="space-between center"] > *,
  [layout-align-md="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-md="start end"],
  [layout-align-md="center end"],
  [layout-align-md="end end"],
  [layout-align-md="space-between end"],
  [layout-align-md="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-md="start stretch"],
  [layout-align-md="center stretch"],
  [layout-align-md="end stretch"],
  [layout-align-md="space-between stretch"],
  [layout-align-md="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-md] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 768px) and (max-width: 1279px) {
  [flex-md] {
    flex: 1 1 0%; } }

@media (min-width: 768px) and (max-width: 1279px) {
  [flex-md-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-md-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-md-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-md-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="0"],
  [layout-md="row"] > [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="0"],
  [layout-md="column"] > [flex-md="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="5"],
  [layout-md="row"] > [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="5"],
  [layout-md="column"] > [flex-md="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="10"],
  [layout-md="row"] > [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="10"],
  [layout-md="column"] > [flex-md="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="15"],
  [layout-md="row"] > [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="15"],
  [layout-md="column"] > [flex-md="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="20"],
  [layout-md="row"] > [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="20"],
  [layout-md="column"] > [flex-md="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="25"],
  [layout-md="row"] > [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="25"],
  [layout-md="column"] > [flex-md="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="30"],
  [layout-md="row"] > [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="30"],
  [layout-md="column"] > [flex-md="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="35"],
  [layout-md="row"] > [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="35"],
  [layout-md="column"] > [flex-md="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="40"],
  [layout-md="row"] > [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="40"],
  [layout-md="column"] > [flex-md="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="45"],
  [layout-md="row"] > [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="45"],
  [layout-md="column"] > [flex-md="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="50"],
  [layout-md="row"] > [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="50"],
  [layout-md="column"] > [flex-md="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="55"],
  [layout-md="row"] > [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="55"],
  [layout-md="column"] > [flex-md="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="60"],
  [layout-md="row"] > [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="60"],
  [layout-md="column"] > [flex-md="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="65"],
  [layout-md="row"] > [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="65"],
  [layout-md="column"] > [flex-md="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="70"],
  [layout-md="row"] > [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="70"],
  [layout-md="column"] > [flex-md="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="75"],
  [layout-md="row"] > [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="75"],
  [layout-md="column"] > [flex-md="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="80"],
  [layout-md="row"] > [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="80"],
  [layout-md="column"] > [flex-md="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="85"],
  [layout-md="row"] > [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="85"],
  [layout-md="column"] > [flex-md="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="90"],
  [layout-md="row"] > [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="90"],
  [layout-md="column"] > [flex-md="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="95"],
  [layout-md="row"] > [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="95"],
  [layout-md="column"] > [flex-md="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="100"],
  [layout-md="row"] > [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="100"],
  [layout-md="column"] > [flex-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="33"], [layout="row"] > [flex-md="33"], [layout-md="row"] > [flex-md="33"], [layout-md="row"] > [flex-md="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-md="66"], [layout="row"] > [flex-md="66"], [layout-md="row"] > [flex-md="66"], [layout-md="row"] > [flex-md="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="33"], [layout="column"] > [flex-md="33"], [layout-md="column"] > [flex-md="33"], [layout-md="column"] > [flex-md="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-md="66"], [layout="column"] > [flex-md="66"], [layout-md="column"] > [flex-md="66"], [layout-md="column"] > [flex-md="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-md], [layout-md="column"], [layout-md="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-md="column"] {
    flex-direction: column; }
  [layout-md="row"] {
    flex-direction: row; } }

@media (min-width: 1280px) {
  [flex-order-gt-md="-20"] {
    order: -20; }
  [flex-order-gt-md="-19"] {
    order: -19; }
  [flex-order-gt-md="-18"] {
    order: -18; }
  [flex-order-gt-md="-17"] {
    order: -17; }
  [flex-order-gt-md="-16"] {
    order: -16; }
  [flex-order-gt-md="-15"] {
    order: -15; }
  [flex-order-gt-md="-14"] {
    order: -14; }
  [flex-order-gt-md="-13"] {
    order: -13; }
  [flex-order-gt-md="-12"] {
    order: -12; }
  [flex-order-gt-md="-11"] {
    order: -11; }
  [flex-order-gt-md="-10"] {
    order: -10; }
  [flex-order-gt-md="-9"] {
    order: -9; }
  [flex-order-gt-md="-8"] {
    order: -8; }
  [flex-order-gt-md="-7"] {
    order: -7; }
  [flex-order-gt-md="-6"] {
    order: -6; }
  [flex-order-gt-md="-5"] {
    order: -5; }
  [flex-order-gt-md="-4"] {
    order: -4; }
  [flex-order-gt-md="-3"] {
    order: -3; }
  [flex-order-gt-md="-2"] {
    order: -2; }
  [flex-order-gt-md="-1"] {
    order: -1; }
  [flex-order-gt-md="0"] {
    order: 0; }
  [flex-order-gt-md="1"] {
    order: 1; }
  [flex-order-gt-md="2"] {
    order: 2; }
  [flex-order-gt-md="3"] {
    order: 3; }
  [flex-order-gt-md="4"] {
    order: 4; }
  [flex-order-gt-md="5"] {
    order: 5; }
  [flex-order-gt-md="6"] {
    order: 6; }
  [flex-order-gt-md="7"] {
    order: 7; }
  [flex-order-gt-md="8"] {
    order: 8; }
  [flex-order-gt-md="9"] {
    order: 9; }
  [flex-order-gt-md="10"] {
    order: 10; }
  [flex-order-gt-md="11"] {
    order: 11; }
  [flex-order-gt-md="12"] {
    order: 12; }
  [flex-order-gt-md="13"] {
    order: 13; }
  [flex-order-gt-md="14"] {
    order: 14; }
  [flex-order-gt-md="15"] {
    order: 15; }
  [flex-order-gt-md="16"] {
    order: 16; }
  [flex-order-gt-md="17"] {
    order: 17; }
  [flex-order-gt-md="18"] {
    order: 18; }
  [flex-order-gt-md="19"] {
    order: 19; }
  [flex-order-gt-md="20"] {
    order: 20; }
  [flex-offset-gt-md="0"] {
    margin-left: 0%; }
  [flex-offset-gt-md="5"] {
    margin-left: 5%; }
  [flex-offset-gt-md="10"] {
    margin-left: 10%; }
  [flex-offset-gt-md="15"] {
    margin-left: 15%; }
  [flex-offset-gt-md="20"] {
    margin-left: 20%; }
  [flex-offset-gt-md="25"] {
    margin-left: 25%; }
  [flex-offset-gt-md="30"] {
    margin-left: 30%; }
  [flex-offset-gt-md="35"] {
    margin-left: 35%; }
  [flex-offset-gt-md="40"] {
    margin-left: 40%; }
  [flex-offset-gt-md="45"] {
    margin-left: 45%; }
  [flex-offset-gt-md="50"] {
    margin-left: 50%; }
  [flex-offset-gt-md="55"] {
    margin-left: 55%; }
  [flex-offset-gt-md="60"] {
    margin-left: 60%; }
  [flex-offset-gt-md="65"] {
    margin-left: 65%; }
  [flex-offset-gt-md="70"] {
    margin-left: 70%; }
  [flex-offset-gt-md="75"] {
    margin-left: 75%; }
  [flex-offset-gt-md="80"] {
    margin-left: 80%; }
  [flex-offset-gt-md="85"] {
    margin-left: 85%; }
  [flex-offset-gt-md="90"] {
    margin-left: 90%; }
  [flex-offset-gt-md="95"] {
    margin-left: 95%; }
  [flex-offset-gt-md="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-md="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-md],
  [layout-align-gt-md="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-md="start"],
  [layout-align-gt-md="start start"],
  [layout-align-gt-md="start center"],
  [layout-align-gt-md="start end"],
  [layout-align-gt-md="start stretch"] {
    justify-content: flex-start; }
  [layout-align-gt-md="center"],
  [layout-align-gt-md="center start"],
  [layout-align-gt-md="center center"],
  [layout-align-gt-md="center end"],
  [layout-align-gt-md="center stretch"] {
    justify-content: center; }
  [layout-align-gt-md="end"],
  [layout-align-gt-md="end center"],
  [layout-align-gt-md="end start"],
  [layout-align-gt-md="end end"],
  [layout-align-gt-md="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-md="space-around"],
  [layout-align-gt-md="space-around center"],
  [layout-align-gt-md="space-around start"],
  [layout-align-gt-md="space-around end"],
  [layout-align-gt-md="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-md="space-between"],
  [layout-align-gt-md="space-between center"],
  [layout-align-gt-md="space-between start"],
  [layout-align-gt-md="space-between end"],
  [layout-align-gt-md="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-md="start start"],
  [layout-align-gt-md="center start"],
  [layout-align-gt-md="end start"],
  [layout-align-gt-md="space-between start"],
  [layout-align-gt-md="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-md="start center"],
  [layout-align-gt-md="center center"],
  [layout-align-gt-md="end center"],
  [layout-align-gt-md="space-between center"],
  [layout-align-gt-md="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-md="start center"] > *,
  [layout-align-gt-md="center center"] > *,
  [layout-align-gt-md="end center"] > *,
  [layout-align-gt-md="space-between center"] > *,
  [layout-align-gt-md="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-md="start end"],
  [layout-align-gt-md="center end"],
  [layout-align-gt-md="end end"],
  [layout-align-gt-md="space-between end"],
  [layout-align-gt-md="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-md="start stretch"],
  [layout-align-gt-md="center stretch"],
  [layout-align-gt-md="end stretch"],
  [layout-align-gt-md="space-between stretch"],
  [layout-align-gt-md="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-md] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) {
  [flex-gt-md] {
    flex: 1 1 0%; } }

@media (min-width: 1280px) {
  [flex-gt-md-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-md-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-md-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-md-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="0"],
  [layout-gt-md="row"] > [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="0"],
  [layout-gt-md="column"] > [flex-gt-md="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="5"],
  [layout-gt-md="row"] > [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="5"],
  [layout-gt-md="column"] > [flex-gt-md="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="10"],
  [layout-gt-md="row"] > [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="10"],
  [layout-gt-md="column"] > [flex-gt-md="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="15"],
  [layout-gt-md="row"] > [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="15"],
  [layout-gt-md="column"] > [flex-gt-md="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="20"],
  [layout-gt-md="row"] > [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="20"],
  [layout-gt-md="column"] > [flex-gt-md="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="25"],
  [layout-gt-md="row"] > [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="25"],
  [layout-gt-md="column"] > [flex-gt-md="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="30"],
  [layout-gt-md="row"] > [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="30"],
  [layout-gt-md="column"] > [flex-gt-md="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="35"],
  [layout-gt-md="row"] > [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="35"],
  [layout-gt-md="column"] > [flex-gt-md="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="40"],
  [layout-gt-md="row"] > [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="40"],
  [layout-gt-md="column"] > [flex-gt-md="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="45"],
  [layout-gt-md="row"] > [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="45"],
  [layout-gt-md="column"] > [flex-gt-md="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="50"],
  [layout-gt-md="row"] > [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="50"],
  [layout-gt-md="column"] > [flex-gt-md="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="55"],
  [layout-gt-md="row"] > [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="55"],
  [layout-gt-md="column"] > [flex-gt-md="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="60"],
  [layout-gt-md="row"] > [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="60"],
  [layout-gt-md="column"] > [flex-gt-md="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="65"],
  [layout-gt-md="row"] > [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="65"],
  [layout-gt-md="column"] > [flex-gt-md="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="70"],
  [layout-gt-md="row"] > [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="70"],
  [layout-gt-md="column"] > [flex-gt-md="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="75"],
  [layout-gt-md="row"] > [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="75"],
  [layout-gt-md="column"] > [flex-gt-md="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="80"],
  [layout-gt-md="row"] > [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="80"],
  [layout-gt-md="column"] > [flex-gt-md="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="85"],
  [layout-gt-md="row"] > [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="85"],
  [layout-gt-md="column"] > [flex-gt-md="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="90"],
  [layout-gt-md="row"] > [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="90"],
  [layout-gt-md="column"] > [flex-gt-md="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="95"],
  [layout-gt-md="row"] > [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="95"],
  [layout-gt-md="column"] > [flex-gt-md="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="100"],
  [layout-gt-md="row"] > [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="100"],
  [layout-gt-md="column"] > [flex-gt-md="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="33"], [layout="row"] > [flex-gt-md="33"], [layout-gt-md="row"] > [flex-gt-md="33"], [layout-gt-md="row"] > [flex-gt-md="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-md="66"], [layout="row"] > [flex-gt-md="66"], [layout-gt-md="row"] > [flex-gt-md="66"], [layout-gt-md="row"] > [flex-gt-md="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="33"], [layout="column"] > [flex-gt-md="33"], [layout-gt-md="column"] > [flex-gt-md="33"], [layout-gt-md="column"] > [flex-gt-md="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-md="66"], [layout="column"] > [flex-gt-md="66"], [layout-gt-md="column"] > [flex-gt-md="66"], [layout-gt-md="column"] > [flex-gt-md="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-gt-md], [layout-gt-md="column"], [layout-gt-md="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-md="column"] {
    flex-direction: column; }
  [layout-gt-md="row"] {
    flex-direction: row; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]), [hide-gt-md]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-lg]):not([show]) {
    display: none; }
  [hide-lg]:not([show-lg]):not([show]) {
    display: none; }
  [flex-order-lg="-20"] {
    order: -20; }
  [flex-order-lg="-19"] {
    order: -19; }
  [flex-order-lg="-18"] {
    order: -18; }
  [flex-order-lg="-17"] {
    order: -17; }
  [flex-order-lg="-16"] {
    order: -16; }
  [flex-order-lg="-15"] {
    order: -15; }
  [flex-order-lg="-14"] {
    order: -14; }
  [flex-order-lg="-13"] {
    order: -13; }
  [flex-order-lg="-12"] {
    order: -12; }
  [flex-order-lg="-11"] {
    order: -11; }
  [flex-order-lg="-10"] {
    order: -10; }
  [flex-order-lg="-9"] {
    order: -9; }
  [flex-order-lg="-8"] {
    order: -8; }
  [flex-order-lg="-7"] {
    order: -7; }
  [flex-order-lg="-6"] {
    order: -6; }
  [flex-order-lg="-5"] {
    order: -5; }
  [flex-order-lg="-4"] {
    order: -4; }
  [flex-order-lg="-3"] {
    order: -3; }
  [flex-order-lg="-2"] {
    order: -2; }
  [flex-order-lg="-1"] {
    order: -1; }
  [flex-order-lg="0"] {
    order: 0; }
  [flex-order-lg="1"] {
    order: 1; }
  [flex-order-lg="2"] {
    order: 2; }
  [flex-order-lg="3"] {
    order: 3; }
  [flex-order-lg="4"] {
    order: 4; }
  [flex-order-lg="5"] {
    order: 5; }
  [flex-order-lg="6"] {
    order: 6; }
  [flex-order-lg="7"] {
    order: 7; }
  [flex-order-lg="8"] {
    order: 8; }
  [flex-order-lg="9"] {
    order: 9; }
  [flex-order-lg="10"] {
    order: 10; }
  [flex-order-lg="11"] {
    order: 11; }
  [flex-order-lg="12"] {
    order: 12; }
  [flex-order-lg="13"] {
    order: 13; }
  [flex-order-lg="14"] {
    order: 14; }
  [flex-order-lg="15"] {
    order: 15; }
  [flex-order-lg="16"] {
    order: 16; }
  [flex-order-lg="17"] {
    order: 17; }
  [flex-order-lg="18"] {
    order: 18; }
  [flex-order-lg="19"] {
    order: 19; }
  [flex-order-lg="20"] {
    order: 20; }
  [flex-offset-lg="0"] {
    margin-left: 0%; }
  [flex-offset-lg="5"] {
    margin-left: 5%; }
  [flex-offset-lg="10"] {
    margin-left: 10%; }
  [flex-offset-lg="15"] {
    margin-left: 15%; }
  [flex-offset-lg="20"] {
    margin-left: 20%; }
  [flex-offset-lg="25"] {
    margin-left: 25%; }
  [flex-offset-lg="30"] {
    margin-left: 30%; }
  [flex-offset-lg="35"] {
    margin-left: 35%; }
  [flex-offset-lg="40"] {
    margin-left: 40%; }
  [flex-offset-lg="45"] {
    margin-left: 45%; }
  [flex-offset-lg="50"] {
    margin-left: 50%; }
  [flex-offset-lg="55"] {
    margin-left: 55%; }
  [flex-offset-lg="60"] {
    margin-left: 60%; }
  [flex-offset-lg="65"] {
    margin-left: 65%; }
  [flex-offset-lg="70"] {
    margin-left: 70%; }
  [flex-offset-lg="75"] {
    margin-left: 75%; }
  [flex-offset-lg="80"] {
    margin-left: 80%; }
  [flex-offset-lg="85"] {
    margin-left: 85%; }
  [flex-offset-lg="90"] {
    margin-left: 90%; }
  [flex-offset-lg="95"] {
    margin-left: 95%; }
  [flex-offset-lg="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-lg="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-lg],
  [layout-align-lg="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-lg="start"],
  [layout-align-lg="start start"],
  [layout-align-lg="start center"],
  [layout-align-lg="start end"],
  [layout-align-lg="start stretch"] {
    justify-content: flex-start; }
  [layout-align-lg="center"],
  [layout-align-lg="center start"],
  [layout-align-lg="center center"],
  [layout-align-lg="center end"],
  [layout-align-lg="center stretch"] {
    justify-content: center; }
  [layout-align-lg="end"],
  [layout-align-lg="end center"],
  [layout-align-lg="end start"],
  [layout-align-lg="end end"],
  [layout-align-lg="end stretch"] {
    justify-content: flex-end; }
  [layout-align-lg="space-around"],
  [layout-align-lg="space-around center"],
  [layout-align-lg="space-around start"],
  [layout-align-lg="space-around end"],
  [layout-align-lg="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-lg="space-between"],
  [layout-align-lg="space-between center"],
  [layout-align-lg="space-between start"],
  [layout-align-lg="space-between end"],
  [layout-align-lg="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-lg="start start"],
  [layout-align-lg="center start"],
  [layout-align-lg="end start"],
  [layout-align-lg="space-between start"],
  [layout-align-lg="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-lg="start center"],
  [layout-align-lg="center center"],
  [layout-align-lg="end center"],
  [layout-align-lg="space-between center"],
  [layout-align-lg="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-lg="start center"] > *,
  [layout-align-lg="center center"] > *,
  [layout-align-lg="end center"] > *,
  [layout-align-lg="space-between center"] > *,
  [layout-align-lg="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-lg="start end"],
  [layout-align-lg="center end"],
  [layout-align-lg="end end"],
  [layout-align-lg="space-between end"],
  [layout-align-lg="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-lg="start stretch"],
  [layout-align-lg="center stretch"],
  [layout-align-lg="end stretch"],
  [layout-align-lg="space-between stretch"],
  [layout-align-lg="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-lg] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) and (max-width: 1919px) {
  [flex-lg] {
    flex: 1 1 0%; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  [flex-lg-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-lg-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-lg-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-lg-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="0"],
  [layout-lg="row"] > [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="0"],
  [layout-lg="column"] > [flex-lg="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="5"],
  [layout-lg="row"] > [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="5"],
  [layout-lg="column"] > [flex-lg="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="10"],
  [layout-lg="row"] > [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="10"],
  [layout-lg="column"] > [flex-lg="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="15"],
  [layout-lg="row"] > [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="15"],
  [layout-lg="column"] > [flex-lg="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="20"],
  [layout-lg="row"] > [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="20"],
  [layout-lg="column"] > [flex-lg="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="25"],
  [layout-lg="row"] > [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="25"],
  [layout-lg="column"] > [flex-lg="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="30"],
  [layout-lg="row"] > [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="30"],
  [layout-lg="column"] > [flex-lg="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="35"],
  [layout-lg="row"] > [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="35"],
  [layout-lg="column"] > [flex-lg="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="40"],
  [layout-lg="row"] > [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="40"],
  [layout-lg="column"] > [flex-lg="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="45"],
  [layout-lg="row"] > [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="45"],
  [layout-lg="column"] > [flex-lg="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="50"],
  [layout-lg="row"] > [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="50"],
  [layout-lg="column"] > [flex-lg="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="55"],
  [layout-lg="row"] > [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="55"],
  [layout-lg="column"] > [flex-lg="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="60"],
  [layout-lg="row"] > [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="60"],
  [layout-lg="column"] > [flex-lg="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="65"],
  [layout-lg="row"] > [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="65"],
  [layout-lg="column"] > [flex-lg="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="70"],
  [layout-lg="row"] > [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="70"],
  [layout-lg="column"] > [flex-lg="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="75"],
  [layout-lg="row"] > [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="75"],
  [layout-lg="column"] > [flex-lg="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="80"],
  [layout-lg="row"] > [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="80"],
  [layout-lg="column"] > [flex-lg="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="85"],
  [layout-lg="row"] > [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="85"],
  [layout-lg="column"] > [flex-lg="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="90"],
  [layout-lg="row"] > [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="90"],
  [layout-lg="column"] > [flex-lg="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="95"],
  [layout-lg="row"] > [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="95"],
  [layout-lg="column"] > [flex-lg="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="100"],
  [layout-lg="row"] > [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="100"],
  [layout-lg="column"] > [flex-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="33"], [layout="row"] > [flex-lg="33"], [layout-lg="row"] > [flex-lg="33"], [layout-lg="row"] > [flex-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-lg="66"], [layout="row"] > [flex-lg="66"], [layout-lg="row"] > [flex-lg="66"], [layout-lg="row"] > [flex-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="33"], [layout="column"] > [flex-lg="33"], [layout-lg="column"] > [flex-lg="33"], [layout-lg="column"] > [flex-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-lg="66"], [layout="column"] > [flex-lg="66"], [layout-lg="column"] > [flex-lg="66"], [layout-lg="column"] > [flex-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-lg], [layout-lg="column"], [layout-lg="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-lg="column"] {
    flex-direction: column; }
  [layout-lg="row"] {
    flex-direction: row; } }

@media (min-width: 1920px) {
  [flex-order-gt-lg="-20"] {
    order: -20; }
  [flex-order-gt-lg="-19"] {
    order: -19; }
  [flex-order-gt-lg="-18"] {
    order: -18; }
  [flex-order-gt-lg="-17"] {
    order: -17; }
  [flex-order-gt-lg="-16"] {
    order: -16; }
  [flex-order-gt-lg="-15"] {
    order: -15; }
  [flex-order-gt-lg="-14"] {
    order: -14; }
  [flex-order-gt-lg="-13"] {
    order: -13; }
  [flex-order-gt-lg="-12"] {
    order: -12; }
  [flex-order-gt-lg="-11"] {
    order: -11; }
  [flex-order-gt-lg="-10"] {
    order: -10; }
  [flex-order-gt-lg="-9"] {
    order: -9; }
  [flex-order-gt-lg="-8"] {
    order: -8; }
  [flex-order-gt-lg="-7"] {
    order: -7; }
  [flex-order-gt-lg="-6"] {
    order: -6; }
  [flex-order-gt-lg="-5"] {
    order: -5; }
  [flex-order-gt-lg="-4"] {
    order: -4; }
  [flex-order-gt-lg="-3"] {
    order: -3; }
  [flex-order-gt-lg="-2"] {
    order: -2; }
  [flex-order-gt-lg="-1"] {
    order: -1; }
  [flex-order-gt-lg="0"] {
    order: 0; }
  [flex-order-gt-lg="1"] {
    order: 1; }
  [flex-order-gt-lg="2"] {
    order: 2; }
  [flex-order-gt-lg="3"] {
    order: 3; }
  [flex-order-gt-lg="4"] {
    order: 4; }
  [flex-order-gt-lg="5"] {
    order: 5; }
  [flex-order-gt-lg="6"] {
    order: 6; }
  [flex-order-gt-lg="7"] {
    order: 7; }
  [flex-order-gt-lg="8"] {
    order: 8; }
  [flex-order-gt-lg="9"] {
    order: 9; }
  [flex-order-gt-lg="10"] {
    order: 10; }
  [flex-order-gt-lg="11"] {
    order: 11; }
  [flex-order-gt-lg="12"] {
    order: 12; }
  [flex-order-gt-lg="13"] {
    order: 13; }
  [flex-order-gt-lg="14"] {
    order: 14; }
  [flex-order-gt-lg="15"] {
    order: 15; }
  [flex-order-gt-lg="16"] {
    order: 16; }
  [flex-order-gt-lg="17"] {
    order: 17; }
  [flex-order-gt-lg="18"] {
    order: 18; }
  [flex-order-gt-lg="19"] {
    order: 19; }
  [flex-order-gt-lg="20"] {
    order: 20; }
  [flex-offset-gt-lg="0"] {
    margin-left: 0%; }
  [flex-offset-gt-lg="5"] {
    margin-left: 5%; }
  [flex-offset-gt-lg="10"] {
    margin-left: 10%; }
  [flex-offset-gt-lg="15"] {
    margin-left: 15%; }
  [flex-offset-gt-lg="20"] {
    margin-left: 20%; }
  [flex-offset-gt-lg="25"] {
    margin-left: 25%; }
  [flex-offset-gt-lg="30"] {
    margin-left: 30%; }
  [flex-offset-gt-lg="35"] {
    margin-left: 35%; }
  [flex-offset-gt-lg="40"] {
    margin-left: 40%; }
  [flex-offset-gt-lg="45"] {
    margin-left: 45%; }
  [flex-offset-gt-lg="50"] {
    margin-left: 50%; }
  [flex-offset-gt-lg="55"] {
    margin-left: 55%; }
  [flex-offset-gt-lg="60"] {
    margin-left: 60%; }
  [flex-offset-gt-lg="65"] {
    margin-left: 65%; }
  [flex-offset-gt-lg="70"] {
    margin-left: 70%; }
  [flex-offset-gt-lg="75"] {
    margin-left: 75%; }
  [flex-offset-gt-lg="80"] {
    margin-left: 80%; }
  [flex-offset-gt-lg="85"] {
    margin-left: 85%; }
  [flex-offset-gt-lg="90"] {
    margin-left: 90%; }
  [flex-offset-gt-lg="95"] {
    margin-left: 95%; }
  [flex-offset-gt-lg="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-gt-lg="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-gt-lg],
  [layout-align-gt-lg="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-gt-lg="start"],
  [layout-align-gt-lg="start start"],
  [layout-align-gt-lg="start center"],
  [layout-align-gt-lg="start end"],
  [layout-align-gt-lg="start stretch"] {
    justify-content: flex-start; }
  [layout-align-gt-lg="center"],
  [layout-align-gt-lg="center start"],
  [layout-align-gt-lg="center center"],
  [layout-align-gt-lg="center end"],
  [layout-align-gt-lg="center stretch"] {
    justify-content: center; }
  [layout-align-gt-lg="end"],
  [layout-align-gt-lg="end center"],
  [layout-align-gt-lg="end start"],
  [layout-align-gt-lg="end end"],
  [layout-align-gt-lg="end stretch"] {
    justify-content: flex-end; }
  [layout-align-gt-lg="space-around"],
  [layout-align-gt-lg="space-around center"],
  [layout-align-gt-lg="space-around start"],
  [layout-align-gt-lg="space-around end"],
  [layout-align-gt-lg="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-gt-lg="space-between"],
  [layout-align-gt-lg="space-between center"],
  [layout-align-gt-lg="space-between start"],
  [layout-align-gt-lg="space-between end"],
  [layout-align-gt-lg="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-gt-lg="start start"],
  [layout-align-gt-lg="center start"],
  [layout-align-gt-lg="end start"],
  [layout-align-gt-lg="space-between start"],
  [layout-align-gt-lg="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-gt-lg="start center"],
  [layout-align-gt-lg="center center"],
  [layout-align-gt-lg="end center"],
  [layout-align-gt-lg="space-between center"],
  [layout-align-gt-lg="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-gt-lg="start center"] > *,
  [layout-align-gt-lg="center center"] > *,
  [layout-align-gt-lg="end center"] > *,
  [layout-align-gt-lg="space-between center"] > *,
  [layout-align-gt-lg="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-gt-lg="start end"],
  [layout-align-gt-lg="center end"],
  [layout-align-gt-lg="end end"],
  [layout-align-gt-lg="space-between end"],
  [layout-align-gt-lg="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-gt-lg="start stretch"],
  [layout-align-gt-lg="center stretch"],
  [layout-align-gt-lg="end stretch"],
  [layout-align-gt-lg="space-between stretch"],
  [layout-align-gt-lg="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-gt-lg] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1920px) {
  [flex-gt-lg] {
    flex: 1 1 0%; } }

@media (min-width: 1920px) {
  [flex-gt-lg-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-gt-lg-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-gt-lg-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-gt-lg-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="0"],
  [layout-gt-lg="row"] > [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="0"],
  [layout-gt-lg="column"] > [flex-gt-lg="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="5"],
  [layout-gt-lg="row"] > [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="5"],
  [layout-gt-lg="column"] > [flex-gt-lg="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="10"],
  [layout-gt-lg="row"] > [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="10"],
  [layout-gt-lg="column"] > [flex-gt-lg="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="15"],
  [layout-gt-lg="row"] > [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="15"],
  [layout-gt-lg="column"] > [flex-gt-lg="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="20"],
  [layout-gt-lg="row"] > [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="20"],
  [layout-gt-lg="column"] > [flex-gt-lg="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="25"],
  [layout-gt-lg="row"] > [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="25"],
  [layout-gt-lg="column"] > [flex-gt-lg="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="30"],
  [layout-gt-lg="row"] > [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="30"],
  [layout-gt-lg="column"] > [flex-gt-lg="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="35"],
  [layout-gt-lg="row"] > [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="35"],
  [layout-gt-lg="column"] > [flex-gt-lg="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="40"],
  [layout-gt-lg="row"] > [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="40"],
  [layout-gt-lg="column"] > [flex-gt-lg="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="45"],
  [layout-gt-lg="row"] > [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="45"],
  [layout-gt-lg="column"] > [flex-gt-lg="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="50"],
  [layout-gt-lg="row"] > [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="50"],
  [layout-gt-lg="column"] > [flex-gt-lg="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="55"],
  [layout-gt-lg="row"] > [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="55"],
  [layout-gt-lg="column"] > [flex-gt-lg="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="60"],
  [layout-gt-lg="row"] > [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="60"],
  [layout-gt-lg="column"] > [flex-gt-lg="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="65"],
  [layout-gt-lg="row"] > [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="65"],
  [layout-gt-lg="column"] > [flex-gt-lg="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="70"],
  [layout-gt-lg="row"] > [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="70"],
  [layout-gt-lg="column"] > [flex-gt-lg="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="75"],
  [layout-gt-lg="row"] > [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="75"],
  [layout-gt-lg="column"] > [flex-gt-lg="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="80"],
  [layout-gt-lg="row"] > [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="80"],
  [layout-gt-lg="column"] > [flex-gt-lg="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="85"],
  [layout-gt-lg="row"] > [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="85"],
  [layout-gt-lg="column"] > [flex-gt-lg="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="90"],
  [layout-gt-lg="row"] > [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="90"],
  [layout-gt-lg="column"] > [flex-gt-lg="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="95"],
  [layout-gt-lg="row"] > [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="95"],
  [layout-gt-lg="column"] > [flex-gt-lg="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="100"],
  [layout-gt-lg="row"] > [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="100"],
  [layout-gt-lg="column"] > [flex-gt-lg="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="33"], [layout="row"] > [flex-gt-lg="33"], [layout-gt-lg="row"] > [flex-gt-lg="33"], [layout-gt-lg="row"] > [flex-gt-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-gt-lg="66"], [layout="row"] > [flex-gt-lg="66"], [layout-gt-lg="row"] > [flex-gt-lg="66"], [layout-gt-lg="row"] > [flex-gt-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="33"], [layout="column"] > [flex-gt-lg="33"], [layout-gt-lg="column"] > [flex-gt-lg="33"], [layout-gt-lg="column"] > [flex-gt-lg="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-gt-lg="66"], [layout="column"] > [flex-gt-lg="66"], [layout-gt-lg="column"] > [flex-gt-lg="66"], [layout-gt-lg="column"] > [flex-gt-lg="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-gt-lg], [layout-gt-lg="column"], [layout-gt-lg="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-gt-lg="column"] {
    flex-direction: column; }
  [layout-gt-lg="row"] {
    flex-direction: row; }
  [flex-order-xl="-20"] {
    order: -20; }
  [flex-order-xl="-19"] {
    order: -19; }
  [flex-order-xl="-18"] {
    order: -18; }
  [flex-order-xl="-17"] {
    order: -17; }
  [flex-order-xl="-16"] {
    order: -16; }
  [flex-order-xl="-15"] {
    order: -15; }
  [flex-order-xl="-14"] {
    order: -14; }
  [flex-order-xl="-13"] {
    order: -13; }
  [flex-order-xl="-12"] {
    order: -12; }
  [flex-order-xl="-11"] {
    order: -11; }
  [flex-order-xl="-10"] {
    order: -10; }
  [flex-order-xl="-9"] {
    order: -9; }
  [flex-order-xl="-8"] {
    order: -8; }
  [flex-order-xl="-7"] {
    order: -7; }
  [flex-order-xl="-6"] {
    order: -6; }
  [flex-order-xl="-5"] {
    order: -5; }
  [flex-order-xl="-4"] {
    order: -4; }
  [flex-order-xl="-3"] {
    order: -3; }
  [flex-order-xl="-2"] {
    order: -2; }
  [flex-order-xl="-1"] {
    order: -1; }
  [flex-order-xl="0"] {
    order: 0; }
  [flex-order-xl="1"] {
    order: 1; }
  [flex-order-xl="2"] {
    order: 2; }
  [flex-order-xl="3"] {
    order: 3; }
  [flex-order-xl="4"] {
    order: 4; }
  [flex-order-xl="5"] {
    order: 5; }
  [flex-order-xl="6"] {
    order: 6; }
  [flex-order-xl="7"] {
    order: 7; }
  [flex-order-xl="8"] {
    order: 8; }
  [flex-order-xl="9"] {
    order: 9; }
  [flex-order-xl="10"] {
    order: 10; }
  [flex-order-xl="11"] {
    order: 11; }
  [flex-order-xl="12"] {
    order: 12; }
  [flex-order-xl="13"] {
    order: 13; }
  [flex-order-xl="14"] {
    order: 14; }
  [flex-order-xl="15"] {
    order: 15; }
  [flex-order-xl="16"] {
    order: 16; }
  [flex-order-xl="17"] {
    order: 17; }
  [flex-order-xl="18"] {
    order: 18; }
  [flex-order-xl="19"] {
    order: 19; }
  [flex-order-xl="20"] {
    order: 20; }
  [flex-offset-xl="0"] {
    margin-left: 0%; }
  [flex-offset-xl="5"] {
    margin-left: 5%; }
  [flex-offset-xl="10"] {
    margin-left: 10%; }
  [flex-offset-xl="15"] {
    margin-left: 15%; }
  [flex-offset-xl="20"] {
    margin-left: 20%; }
  [flex-offset-xl="25"] {
    margin-left: 25%; }
  [flex-offset-xl="30"] {
    margin-left: 30%; }
  [flex-offset-xl="35"] {
    margin-left: 35%; }
  [flex-offset-xl="40"] {
    margin-left: 40%; }
  [flex-offset-xl="45"] {
    margin-left: 45%; }
  [flex-offset-xl="50"] {
    margin-left: 50%; }
  [flex-offset-xl="55"] {
    margin-left: 55%; }
  [flex-offset-xl="60"] {
    margin-left: 60%; }
  [flex-offset-xl="65"] {
    margin-left: 65%; }
  [flex-offset-xl="70"] {
    margin-left: 70%; }
  [flex-offset-xl="75"] {
    margin-left: 75%; }
  [flex-offset-xl="80"] {
    margin-left: 80%; }
  [flex-offset-xl="85"] {
    margin-left: 85%; }
  [flex-offset-xl="90"] {
    margin-left: 90%; }
  [flex-offset-xl="95"] {
    margin-left: 95%; }
  [flex-offset-xl="33"] {
    margin-left: calc(100% / 3); }
  [flex-offset-xl="66"] {
    margin-left: calc(200% / 3); }
  [layout-align-xl],
  [layout-align-xl="start stretch"] {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  [layout-align-xl="start"],
  [layout-align-xl="start start"],
  [layout-align-xl="start center"],
  [layout-align-xl="start end"],
  [layout-align-xl="start stretch"] {
    justify-content: flex-start; }
  [layout-align-xl="center"],
  [layout-align-xl="center start"],
  [layout-align-xl="center center"],
  [layout-align-xl="center end"],
  [layout-align-xl="center stretch"] {
    justify-content: center; }
  [layout-align-xl="end"],
  [layout-align-xl="end center"],
  [layout-align-xl="end start"],
  [layout-align-xl="end end"],
  [layout-align-xl="end stretch"] {
    justify-content: flex-end; }
  [layout-align-xl="space-around"],
  [layout-align-xl="space-around center"],
  [layout-align-xl="space-around start"],
  [layout-align-xl="space-around end"],
  [layout-align-xl="space-around stretch"] {
    justify-content: space-around; }
  [layout-align-xl="space-between"],
  [layout-align-xl="space-between center"],
  [layout-align-xl="space-between start"],
  [layout-align-xl="space-between end"],
  [layout-align-xl="space-between stretch"] {
    justify-content: space-between; }
  [layout-align-xl="start start"],
  [layout-align-xl="center start"],
  [layout-align-xl="end start"],
  [layout-align-xl="space-between start"],
  [layout-align-xl="space-around start"] {
    align-items: flex-start;
    align-content: flex-start; }
  [layout-align-xl="start center"],
  [layout-align-xl="center center"],
  [layout-align-xl="end center"],
  [layout-align-xl="space-between center"],
  [layout-align-xl="space-around center"] {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  [layout-align-xl="start center"] > *,
  [layout-align-xl="center center"] > *,
  [layout-align-xl="end center"] > *,
  [layout-align-xl="space-between center"] > *,
  [layout-align-xl="space-around center"] > * {
    max-width: 100%;
    box-sizing: border-box; }
  [layout-align-xl="start end"],
  [layout-align-xl="center end"],
  [layout-align-xl="end end"],
  [layout-align-xl="space-between end"],
  [layout-align-xl="space-around end"] {
    align-items: flex-end;
    align-content: flex-end; }
  [layout-align-xl="start stretch"],
  [layout-align-xl="center stretch"],
  [layout-align-xl="end stretch"],
  [layout-align-xl="space-between stretch"],
  [layout-align-xl="space-around stretch"] {
    align-items: stretch;
    align-content: stretch; }
  [flex-xl] {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1920px) {
  [flex-xl] {
    flex: 1 1 0%; } }

@media (min-width: 1920px) {
  [flex-xl-grow] {
    flex: 1 1 100%;
    box-sizing: border-box; }
  [flex-xl-initial] {
    flex: 0 1 auto;
    box-sizing: border-box; }
  [flex-xl-auto] {
    flex: 1 1 auto;
    box-sizing: border-box; }
  [flex-xl-none] {
    flex: 0 0 auto;
    box-sizing: border-box; }
  [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="0"],
  [layout-xl="row"] > [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="0"],
  [layout-xl="column"] > [flex-xl="0"] {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="5"],
  [layout-xl="row"] > [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="5"],
  [layout-xl="column"] > [flex-xl="5"] {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="10"],
  [layout-xl="row"] > [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="10"],
  [layout-xl="column"] > [flex-xl="10"] {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="15"],
  [layout-xl="row"] > [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="15"],
  [layout-xl="column"] > [flex-xl="15"] {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="20"],
  [layout-xl="row"] > [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="20"],
  [layout-xl="column"] > [flex-xl="20"] {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="25"],
  [layout-xl="row"] > [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="25"],
  [layout-xl="column"] > [flex-xl="25"] {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="30"],
  [layout-xl="row"] > [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="30"],
  [layout-xl="column"] > [flex-xl="30"] {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="35"],
  [layout-xl="row"] > [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="35"],
  [layout-xl="column"] > [flex-xl="35"] {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="40"],
  [layout-xl="row"] > [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="40"],
  [layout-xl="column"] > [flex-xl="40"] {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="45"],
  [layout-xl="row"] > [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="45"],
  [layout-xl="column"] > [flex-xl="45"] {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="50"],
  [layout-xl="row"] > [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="50"],
  [layout-xl="column"] > [flex-xl="50"] {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="55"],
  [layout-xl="row"] > [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="55"],
  [layout-xl="column"] > [flex-xl="55"] {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="60"],
  [layout-xl="row"] > [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="60"],
  [layout-xl="column"] > [flex-xl="60"] {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="65"],
  [layout-xl="row"] > [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="65"],
  [layout-xl="column"] > [flex-xl="65"] {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="70"],
  [layout-xl="row"] > [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="70"],
  [layout-xl="column"] > [flex-xl="70"] {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="75"],
  [layout-xl="row"] > [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="75"],
  [layout-xl="column"] > [flex-xl="75"] {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="80"],
  [layout-xl="row"] > [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="80"],
  [layout-xl="column"] > [flex-xl="80"] {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="85"],
  [layout-xl="row"] > [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="85"],
  [layout-xl="column"] > [flex-xl="85"] {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="90"],
  [layout-xl="row"] > [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="90"],
  [layout-xl="column"] > [flex-xl="90"] {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="95"],
  [layout-xl="row"] > [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="95"],
  [layout-xl="column"] > [flex-xl="95"] {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="100"],
  [layout-xl="row"] > [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="100"],
  [layout-xl="column"] > [flex-xl="100"] {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="33"], [layout="row"] > [flex-xl="33"], [layout-xl="row"] > [flex-xl="33"], [layout-xl="row"] > [flex-xl="33"] {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="row"] > [flex-xl="66"], [layout="row"] > [flex-xl="66"], [layout-xl="row"] > [flex-xl="66"], [layout-xl="row"] > [flex-xl="66"] {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="33"], [layout="column"] > [flex-xl="33"], [layout-xl="column"] > [flex-xl="33"], [layout-xl="column"] > [flex-xl="33"] {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  [layout="column"] > [flex-xl="66"], [layout="column"] > [flex-xl="66"], [layout-xl="column"] > [flex-xl="66"], [layout-xl="column"] > [flex-xl="66"] {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  [layout-xl], [layout-xl="column"], [layout-xl="row"] {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  [layout-xl="column"] {
    flex-direction: column; }
  [layout-xl="row"] {
    flex-direction: row; }
  [hide]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-xs]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-sm]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-md]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]), [hide-gt-lg]:not([show-gt-xs]):not([show-gt-sm]):not([show-gt-md]):not([show-gt-lg]):not([show-xl]):not([show]) {
    display: none; }
  [hide-xl]:not([show-xl]):not([show-gt-lg]):not([show]) {
    display: none; } }

@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/*
*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*
*
*/
/* Apply Mixins to create Layout/Flexbox styles */
.flex-order {
  order: 0; }

.flex-order--20 {
  order: -20; }

.flex-order--19 {
  order: -19; }

.flex-order--18 {
  order: -18; }

.flex-order--17 {
  order: -17; }

.flex-order--16 {
  order: -16; }

.flex-order--15 {
  order: -15; }

.flex-order--14 {
  order: -14; }

.flex-order--13 {
  order: -13; }

.flex-order--12 {
  order: -12; }

.flex-order--11 {
  order: -11; }

.flex-order--10 {
  order: -10; }

.flex-order--9 {
  order: -9; }

.flex-order--8 {
  order: -8; }

.flex-order--7 {
  order: -7; }

.flex-order--6 {
  order: -6; }

.flex-order--5 {
  order: -5; }

.flex-order--4 {
  order: -4; }

.flex-order--3 {
  order: -3; }

.flex-order--2 {
  order: -2; }

.flex-order--1 {
  order: -1; }

.flex-order-0 {
  order: 0; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

.flex-order-4 {
  order: 4; }

.flex-order-5 {
  order: 5; }

.flex-order-6 {
  order: 6; }

.flex-order-7 {
  order: 7; }

.flex-order-8 {
  order: 8; }

.flex-order-9 {
  order: 9; }

.flex-order-10 {
  order: 10; }

.flex-order-11 {
  order: 11; }

.flex-order-12 {
  order: 12; }

.flex-order-13 {
  order: 13; }

.flex-order-14 {
  order: 14; }

.flex-order-15 {
  order: 15; }

.flex-order-16 {
  order: 16; }

.flex-order-17 {
  order: 17; }

.flex-order-18 {
  order: 18; }

.flex-order-19 {
  order: 19; }

.flex-order-20 {
  order: 20; }

.offset-0, .flex-offset-0 {
  margin-left: 0%; }

.offset-5, .flex-offset-5 {
  margin-left: 5%; }

.offset-10, .flex-offset-10 {
  margin-left: 10%; }

.offset-15, .flex-offset-15 {
  margin-left: 15%; }

.offset-20, .flex-offset-20 {
  margin-left: 20%; }

.offset-25, .flex-offset-25 {
  margin-left: 25%; }

.offset-30, .flex-offset-30 {
  margin-left: 30%; }

.offset-35, .flex-offset-35 {
  margin-left: 35%; }

.offset-40, .flex-offset-40 {
  margin-left: 40%; }

.offset-45, .flex-offset-45 {
  margin-left: 45%; }

.offset-50, .flex-offset-50 {
  margin-left: 50%; }

.offset-55, .flex-offset-55 {
  margin-left: 55%; }

.offset-60, .flex-offset-60 {
  margin-left: 60%; }

.offset-65, .flex-offset-65 {
  margin-left: 65%; }

.offset-70, .flex-offset-70 {
  margin-left: 70%; }

.offset-75, .flex-offset-75 {
  margin-left: 75%; }

.offset-80, .flex-offset-80 {
  margin-left: 80%; }

.offset-85, .flex-offset-85 {
  margin-left: 85%; }

.offset-90, .flex-offset-90 {
  margin-left: 90%; }

.offset-95, .flex-offset-95 {
  margin-left: 95%; }

.offset-33, .flex-offset-33 {
  margin-left: calc(100% / 3); }

.offset-66, .flex-offset-66 {
  margin-left: calc(200% / 3); }

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start; }

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center; }

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end; }

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around; }

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between; }

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start; }

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%; }

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box; }

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end; }

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch; }

.flex {
  flex: 1;
  box-sizing: border-box; }

@media screen\0 {
  .flex {
    flex: 1 1 0%; } }

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box; }

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box; }

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box; }

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box; }

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-0,
.layout-row > .flex-0 {
  flex: 1 1 0%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0; }

.layout-column > .flex-0,
.layout-column > .flex-0 {
  flex: 1 1 0%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
  min-height: 0; }

.flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-5,
.layout-row > .flex-5 {
  flex: 1 1 5%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-5,
.layout-column > .flex-5 {
  flex: 1 1 5%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

.flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-10,
.layout-row > .flex-10 {
  flex: 1 1 10%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-10,
.layout-column > .flex-10 {
  flex: 1 1 10%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

.flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-15,
.layout-row > .flex-15 {
  flex: 1 1 15%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-15,
.layout-column > .flex-15 {
  flex: 1 1 15%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

.flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-20,
.layout-row > .flex-20 {
  flex: 1 1 20%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-20,
.layout-column > .flex-20 {
  flex: 1 1 20%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

.flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-25,
.layout-row > .flex-25 {
  flex: 1 1 25%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-25,
.layout-column > .flex-25 {
  flex: 1 1 25%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

.flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-30,
.layout-row > .flex-30 {
  flex: 1 1 30%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-30,
.layout-column > .flex-30 {
  flex: 1 1 30%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

.flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-35,
.layout-row > .flex-35 {
  flex: 1 1 35%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-35,
.layout-column > .flex-35 {
  flex: 1 1 35%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

.flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-40,
.layout-row > .flex-40 {
  flex: 1 1 40%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-40,
.layout-column > .flex-40 {
  flex: 1 1 40%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

.flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-45,
.layout-row > .flex-45 {
  flex: 1 1 45%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-45,
.layout-column > .flex-45 {
  flex: 1 1 45%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

.flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-50,
.layout-row > .flex-50 {
  flex: 1 1 50%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-50,
.layout-column > .flex-50 {
  flex: 1 1 50%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

.flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-55,
.layout-row > .flex-55 {
  flex: 1 1 55%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-55,
.layout-column > .flex-55 {
  flex: 1 1 55%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

.flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-60,
.layout-row > .flex-60 {
  flex: 1 1 60%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-60,
.layout-column > .flex-60 {
  flex: 1 1 60%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

.flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-65,
.layout-row > .flex-65 {
  flex: 1 1 65%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-65,
.layout-column > .flex-65 {
  flex: 1 1 65%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

.flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-70,
.layout-row > .flex-70 {
  flex: 1 1 70%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-70,
.layout-column > .flex-70 {
  flex: 1 1 70%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

.flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-75,
.layout-row > .flex-75 {
  flex: 1 1 75%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-75,
.layout-column > .flex-75 {
  flex: 1 1 75%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

.flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-80,
.layout-row > .flex-80 {
  flex: 1 1 80%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-80,
.layout-column > .flex-80 {
  flex: 1 1 80%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

.flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-85,
.layout-row > .flex-85 {
  flex: 1 1 85%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-85,
.layout-column > .flex-85 {
  flex: 1 1 85%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

.flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-90,
.layout-row > .flex-90 {
  flex: 1 1 90%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-90,
.layout-column > .flex-90 {
  flex: 1 1 90%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

.flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-95,
.layout-row > .flex-95 {
  flex: 1 1 95%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-95,
.layout-column > .flex-95 {
  flex: 1 1 95%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-100,
.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-100,
.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-33, .layout-row > .flex-33, .layout-row > .flex-33, .layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66, .layout-row > .flex-66, .layout-row > .flex-66, .layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex, .layout-row > .flex {
  min-width: 0; }

.layout-column > .flex-33, .layout-column > .flex-33, .layout-column > .flex-33, .layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66, .layout-column > .flex-66, .layout-column > .flex-66, .layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-column > .flex, .layout-column > .flex {
  min-height: 0; }

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.layout-column {
  flex-direction: column; }

.layout-row {
  flex-direction: row; }

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 5px; }

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 10px; }

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 20px; }

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 5px; }

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 10px; }

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 20px; }

.layout-wrap {
  flex-wrap: wrap; }

.layout-nowrap {
  flex-wrap: nowrap; }

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 567px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none; }
  .flex-order-xs--20 {
    order: -20; }
  .flex-order-xs--19 {
    order: -19; }
  .flex-order-xs--18 {
    order: -18; }
  .flex-order-xs--17 {
    order: -17; }
  .flex-order-xs--16 {
    order: -16; }
  .flex-order-xs--15 {
    order: -15; }
  .flex-order-xs--14 {
    order: -14; }
  .flex-order-xs--13 {
    order: -13; }
  .flex-order-xs--12 {
    order: -12; }
  .flex-order-xs--11 {
    order: -11; }
  .flex-order-xs--10 {
    order: -10; }
  .flex-order-xs--9 {
    order: -9; }
  .flex-order-xs--8 {
    order: -8; }
  .flex-order-xs--7 {
    order: -7; }
  .flex-order-xs--6 {
    order: -6; }
  .flex-order-xs--5 {
    order: -5; }
  .flex-order-xs--4 {
    order: -4; }
  .flex-order-xs--3 {
    order: -3; }
  .flex-order-xs--2 {
    order: -2; }
  .flex-order-xs--1 {
    order: -1; }
  .flex-order-xs-0 {
    order: 0; }
  .flex-order-xs-1 {
    order: 1; }
  .flex-order-xs-2 {
    order: 2; }
  .flex-order-xs-3 {
    order: 3; }
  .flex-order-xs-4 {
    order: 4; }
  .flex-order-xs-5 {
    order: 5; }
  .flex-order-xs-6 {
    order: 6; }
  .flex-order-xs-7 {
    order: 7; }
  .flex-order-xs-8 {
    order: 8; }
  .flex-order-xs-9 {
    order: 9; }
  .flex-order-xs-10 {
    order: 10; }
  .flex-order-xs-11 {
    order: 11; }
  .flex-order-xs-12 {
    order: 12; }
  .flex-order-xs-13 {
    order: 13; }
  .flex-order-xs-14 {
    order: 14; }
  .flex-order-xs-15 {
    order: 15; }
  .flex-order-xs-16 {
    order: 16; }
  .flex-order-xs-17 {
    order: 17; }
  .flex-order-xs-18 {
    order: 18; }
  .flex-order-xs-19 {
    order: 19; }
  .flex-order-xs-20 {
    order: 20; }
  .offset-xs-0, .flex-offset-xs-0 {
    margin-left: 0%; }
  .offset-xs-5, .flex-offset-xs-5 {
    margin-left: 5%; }
  .offset-xs-10, .flex-offset-xs-10 {
    margin-left: 10%; }
  .offset-xs-15, .flex-offset-xs-15 {
    margin-left: 15%; }
  .offset-xs-20, .flex-offset-xs-20 {
    margin-left: 20%; }
  .offset-xs-25, .flex-offset-xs-25 {
    margin-left: 25%; }
  .offset-xs-30, .flex-offset-xs-30 {
    margin-left: 30%; }
  .offset-xs-35, .flex-offset-xs-35 {
    margin-left: 35%; }
  .offset-xs-40, .flex-offset-xs-40 {
    margin-left: 40%; }
  .offset-xs-45, .flex-offset-xs-45 {
    margin-left: 45%; }
  .offset-xs-50, .flex-offset-xs-50 {
    margin-left: 50%; }
  .offset-xs-55, .flex-offset-xs-55 {
    margin-left: 55%; }
  .offset-xs-60, .flex-offset-xs-60 {
    margin-left: 60%; }
  .offset-xs-65, .flex-offset-xs-65 {
    margin-left: 65%; }
  .offset-xs-70, .flex-offset-xs-70 {
    margin-left: 70%; }
  .offset-xs-75, .flex-offset-xs-75 {
    margin-left: 75%; }
  .offset-xs-80, .flex-offset-xs-80 {
    margin-left: 80%; }
  .offset-xs-85, .flex-offset-xs-85 {
    margin-left: 85%; }
  .offset-xs-90, .flex-offset-xs-90 {
    margin-left: 90%; }
  .offset-xs-95, .flex-offset-xs-95 {
    margin-left: 95%; }
  .offset-xs-33, .flex-offset-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-xs-66, .flex-offset-xs-66 {
    margin-left: calc(200% / 3); }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center; }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xs {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (max-width: 567px) {
  .flex-xs {
    flex: 1 1 0%; } }

@media (max-width: 567px) {
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-0,
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xs-0,
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-5,
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-5,
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-10,
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-10,
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-15,
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-15,
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-20,
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-20,
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-25,
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-25,
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-30,
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-30,
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-35,
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-35,
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-40,
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-40,
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-45,
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-45,
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-50,
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-50,
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-55,
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-55,
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-60,
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-60,
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-65,
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-65,
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-70,
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-70,
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-75,
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-75,
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-80,
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-80,
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-85,
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-85,
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-90,
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-90,
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-95,
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-95,
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-100,
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-100,
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-33, .layout-row > .flex-xs-33, .layout-xs-row > .flex-xs-33, .layout-xs-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-66, .layout-row > .flex-xs-66, .layout-xs-row > .flex-xs-66, .layout-xs-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-xs-row > .flex {
    min-width: 0; }
  .layout-column > .flex-xs-33, .layout-column > .flex-xs-33, .layout-xs-column > .flex-xs-33, .layout-xs-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-66, .layout-column > .flex-xs-66, .layout-xs-column > .flex-xs-66, .layout-xs-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-xs-column > .flex {
    min-height: 0; }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xs-column {
    flex-direction: column; }
  .layout-xs-row {
    flex-direction: row; } }

@media (min-width: 568px) {
  .flex-order-gt-xs--20 {
    order: -20; }
  .flex-order-gt-xs--19 {
    order: -19; }
  .flex-order-gt-xs--18 {
    order: -18; }
  .flex-order-gt-xs--17 {
    order: -17; }
  .flex-order-gt-xs--16 {
    order: -16; }
  .flex-order-gt-xs--15 {
    order: -15; }
  .flex-order-gt-xs--14 {
    order: -14; }
  .flex-order-gt-xs--13 {
    order: -13; }
  .flex-order-gt-xs--12 {
    order: -12; }
  .flex-order-gt-xs--11 {
    order: -11; }
  .flex-order-gt-xs--10 {
    order: -10; }
  .flex-order-gt-xs--9 {
    order: -9; }
  .flex-order-gt-xs--8 {
    order: -8; }
  .flex-order-gt-xs--7 {
    order: -7; }
  .flex-order-gt-xs--6 {
    order: -6; }
  .flex-order-gt-xs--5 {
    order: -5; }
  .flex-order-gt-xs--4 {
    order: -4; }
  .flex-order-gt-xs--3 {
    order: -3; }
  .flex-order-gt-xs--2 {
    order: -2; }
  .flex-order-gt-xs--1 {
    order: -1; }
  .flex-order-gt-xs-0 {
    order: 0; }
  .flex-order-gt-xs-1 {
    order: 1; }
  .flex-order-gt-xs-2 {
    order: 2; }
  .flex-order-gt-xs-3 {
    order: 3; }
  .flex-order-gt-xs-4 {
    order: 4; }
  .flex-order-gt-xs-5 {
    order: 5; }
  .flex-order-gt-xs-6 {
    order: 6; }
  .flex-order-gt-xs-7 {
    order: 7; }
  .flex-order-gt-xs-8 {
    order: 8; }
  .flex-order-gt-xs-9 {
    order: 9; }
  .flex-order-gt-xs-10 {
    order: 10; }
  .flex-order-gt-xs-11 {
    order: 11; }
  .flex-order-gt-xs-12 {
    order: 12; }
  .flex-order-gt-xs-13 {
    order: 13; }
  .flex-order-gt-xs-14 {
    order: 14; }
  .flex-order-gt-xs-15 {
    order: 15; }
  .flex-order-gt-xs-16 {
    order: 16; }
  .flex-order-gt-xs-17 {
    order: 17; }
  .flex-order-gt-xs-18 {
    order: 18; }
  .flex-order-gt-xs-19 {
    order: 19; }
  .flex-order-gt-xs-20 {
    order: 20; }
  .offset-gt-xs-0, .flex-offset-gt-xs-0 {
    margin-left: 0%; }
  .offset-gt-xs-5, .flex-offset-gt-xs-5 {
    margin-left: 5%; }
  .offset-gt-xs-10, .flex-offset-gt-xs-10 {
    margin-left: 10%; }
  .offset-gt-xs-15, .flex-offset-gt-xs-15 {
    margin-left: 15%; }
  .offset-gt-xs-20, .flex-offset-gt-xs-20 {
    margin-left: 20%; }
  .offset-gt-xs-25, .flex-offset-gt-xs-25 {
    margin-left: 25%; }
  .offset-gt-xs-30, .flex-offset-gt-xs-30 {
    margin-left: 30%; }
  .offset-gt-xs-35, .flex-offset-gt-xs-35 {
    margin-left: 35%; }
  .offset-gt-xs-40, .flex-offset-gt-xs-40 {
    margin-left: 40%; }
  .offset-gt-xs-45, .flex-offset-gt-xs-45 {
    margin-left: 45%; }
  .offset-gt-xs-50, .flex-offset-gt-xs-50 {
    margin-left: 50%; }
  .offset-gt-xs-55, .flex-offset-gt-xs-55 {
    margin-left: 55%; }
  .offset-gt-xs-60, .flex-offset-gt-xs-60 {
    margin-left: 60%; }
  .offset-gt-xs-65, .flex-offset-gt-xs-65 {
    margin-left: 65%; }
  .offset-gt-xs-70, .flex-offset-gt-xs-70 {
    margin-left: 70%; }
  .offset-gt-xs-75, .flex-offset-gt-xs-75 {
    margin-left: 75%; }
  .offset-gt-xs-80, .flex-offset-gt-xs-80 {
    margin-left: 80%; }
  .offset-gt-xs-85, .flex-offset-gt-xs-85 {
    margin-left: 85%; }
  .offset-gt-xs-90, .flex-offset-gt-xs-90 {
    margin-left: 90%; }
  .offset-gt-xs-95, .flex-offset-gt-xs-95 {
    margin-left: 95%; }
  .offset-gt-xs-33, .flex-offset-gt-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-xs-66, .flex-offset-gt-xs-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center; }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 568px) {
  .flex-gt-xs {
    flex: 1 1 0%; } }

@media (min-width: 568px) {
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-0,
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-xs-0,
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-5,
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-5,
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-10,
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-10,
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-15,
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-15,
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-20,
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-20,
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-25,
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-25,
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-30,
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-30,
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-35,
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-35,
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-40,
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-40,
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-45,
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-45,
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-50,
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-50,
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-55,
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-55,
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-60,
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-60,
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-65,
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-65,
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-70,
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-70,
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-75,
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-75,
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-80,
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-80,
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-85,
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-85,
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-90,
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-90,
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-95,
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-95,
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-100,
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-100,
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-33, .layout-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33, .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-66, .layout-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66, .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-xs-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-xs-33, .layout-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33, .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-66, .layout-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66, .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-xs-column > .flex {
    min-height: 0; }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-xs-column {
    flex-direction: column; }
  .layout-gt-xs-row {
    flex-direction: row; } }

@media (min-width: 568px) and (max-width: 767px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .flex-order-sm--20 {
    order: -20; }
  .flex-order-sm--19 {
    order: -19; }
  .flex-order-sm--18 {
    order: -18; }
  .flex-order-sm--17 {
    order: -17; }
  .flex-order-sm--16 {
    order: -16; }
  .flex-order-sm--15 {
    order: -15; }
  .flex-order-sm--14 {
    order: -14; }
  .flex-order-sm--13 {
    order: -13; }
  .flex-order-sm--12 {
    order: -12; }
  .flex-order-sm--11 {
    order: -11; }
  .flex-order-sm--10 {
    order: -10; }
  .flex-order-sm--9 {
    order: -9; }
  .flex-order-sm--8 {
    order: -8; }
  .flex-order-sm--7 {
    order: -7; }
  .flex-order-sm--6 {
    order: -6; }
  .flex-order-sm--5 {
    order: -5; }
  .flex-order-sm--4 {
    order: -4; }
  .flex-order-sm--3 {
    order: -3; }
  .flex-order-sm--2 {
    order: -2; }
  .flex-order-sm--1 {
    order: -1; }
  .flex-order-sm-0 {
    order: 0; }
  .flex-order-sm-1 {
    order: 1; }
  .flex-order-sm-2 {
    order: 2; }
  .flex-order-sm-3 {
    order: 3; }
  .flex-order-sm-4 {
    order: 4; }
  .flex-order-sm-5 {
    order: 5; }
  .flex-order-sm-6 {
    order: 6; }
  .flex-order-sm-7 {
    order: 7; }
  .flex-order-sm-8 {
    order: 8; }
  .flex-order-sm-9 {
    order: 9; }
  .flex-order-sm-10 {
    order: 10; }
  .flex-order-sm-11 {
    order: 11; }
  .flex-order-sm-12 {
    order: 12; }
  .flex-order-sm-13 {
    order: 13; }
  .flex-order-sm-14 {
    order: 14; }
  .flex-order-sm-15 {
    order: 15; }
  .flex-order-sm-16 {
    order: 16; }
  .flex-order-sm-17 {
    order: 17; }
  .flex-order-sm-18 {
    order: 18; }
  .flex-order-sm-19 {
    order: 19; }
  .flex-order-sm-20 {
    order: 20; }
  .offset-sm-0, .flex-offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-5, .flex-offset-sm-5 {
    margin-left: 5%; }
  .offset-sm-10, .flex-offset-sm-10 {
    margin-left: 10%; }
  .offset-sm-15, .flex-offset-sm-15 {
    margin-left: 15%; }
  .offset-sm-20, .flex-offset-sm-20 {
    margin-left: 20%; }
  .offset-sm-25, .flex-offset-sm-25 {
    margin-left: 25%; }
  .offset-sm-30, .flex-offset-sm-30 {
    margin-left: 30%; }
  .offset-sm-35, .flex-offset-sm-35 {
    margin-left: 35%; }
  .offset-sm-40, .flex-offset-sm-40 {
    margin-left: 40%; }
  .offset-sm-45, .flex-offset-sm-45 {
    margin-left: 45%; }
  .offset-sm-50, .flex-offset-sm-50 {
    margin-left: 50%; }
  .offset-sm-55, .flex-offset-sm-55 {
    margin-left: 55%; }
  .offset-sm-60, .flex-offset-sm-60 {
    margin-left: 60%; }
  .offset-sm-65, .flex-offset-sm-65 {
    margin-left: 65%; }
  .offset-sm-70, .flex-offset-sm-70 {
    margin-left: 70%; }
  .offset-sm-75, .flex-offset-sm-75 {
    margin-left: 75%; }
  .offset-sm-80, .flex-offset-sm-80 {
    margin-left: 80%; }
  .offset-sm-85, .flex-offset-sm-85 {
    margin-left: 85%; }
  .offset-sm-90, .flex-offset-sm-90 {
    margin-left: 90%; }
  .offset-sm-95, .flex-offset-sm-95 {
    margin-left: 95%; }
  .offset-sm-33, .flex-offset-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-sm-66, .flex-offset-sm-66 {
    margin-left: calc(200% / 3); }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center; }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-sm {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 568px) and (max-width: 767px) {
  .flex-sm {
    flex: 1 1 0%; } }

@media (min-width: 568px) and (max-width: 767px) {
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-0,
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-sm-0,
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-5,
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-5,
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-10,
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-10,
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-15,
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-15,
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-20,
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-20,
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-25,
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-25,
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-30,
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-30,
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-35,
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-35,
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-40,
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-40,
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-45,
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-45,
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-50,
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-50,
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-55,
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-55,
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-60,
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-60,
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-65,
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-65,
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-70,
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-70,
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-75,
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-75,
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-80,
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-80,
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-85,
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-85,
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-90,
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-90,
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-95,
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-95,
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-100,
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-100,
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-33, .layout-row > .flex-sm-33, .layout-sm-row > .flex-sm-33, .layout-sm-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-66, .layout-row > .flex-sm-66, .layout-sm-row > .flex-sm-66, .layout-sm-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-sm-row > .flex {
    min-width: 0; }
  .layout-column > .flex-sm-33, .layout-column > .flex-sm-33, .layout-sm-column > .flex-sm-33, .layout-sm-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-66, .layout-column > .flex-sm-66, .layout-sm-column > .flex-sm-66, .layout-sm-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-sm-column > .flex {
    min-height: 0; }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-sm-column {
    flex-direction: column; }
  .layout-sm-row {
    flex-direction: row; } }

@media (min-width: 768px) {
  .flex-order-gt-sm--20 {
    order: -20; }
  .flex-order-gt-sm--19 {
    order: -19; }
  .flex-order-gt-sm--18 {
    order: -18; }
  .flex-order-gt-sm--17 {
    order: -17; }
  .flex-order-gt-sm--16 {
    order: -16; }
  .flex-order-gt-sm--15 {
    order: -15; }
  .flex-order-gt-sm--14 {
    order: -14; }
  .flex-order-gt-sm--13 {
    order: -13; }
  .flex-order-gt-sm--12 {
    order: -12; }
  .flex-order-gt-sm--11 {
    order: -11; }
  .flex-order-gt-sm--10 {
    order: -10; }
  .flex-order-gt-sm--9 {
    order: -9; }
  .flex-order-gt-sm--8 {
    order: -8; }
  .flex-order-gt-sm--7 {
    order: -7; }
  .flex-order-gt-sm--6 {
    order: -6; }
  .flex-order-gt-sm--5 {
    order: -5; }
  .flex-order-gt-sm--4 {
    order: -4; }
  .flex-order-gt-sm--3 {
    order: -3; }
  .flex-order-gt-sm--2 {
    order: -2; }
  .flex-order-gt-sm--1 {
    order: -1; }
  .flex-order-gt-sm-0 {
    order: 0; }
  .flex-order-gt-sm-1 {
    order: 1; }
  .flex-order-gt-sm-2 {
    order: 2; }
  .flex-order-gt-sm-3 {
    order: 3; }
  .flex-order-gt-sm-4 {
    order: 4; }
  .flex-order-gt-sm-5 {
    order: 5; }
  .flex-order-gt-sm-6 {
    order: 6; }
  .flex-order-gt-sm-7 {
    order: 7; }
  .flex-order-gt-sm-8 {
    order: 8; }
  .flex-order-gt-sm-9 {
    order: 9; }
  .flex-order-gt-sm-10 {
    order: 10; }
  .flex-order-gt-sm-11 {
    order: 11; }
  .flex-order-gt-sm-12 {
    order: 12; }
  .flex-order-gt-sm-13 {
    order: 13; }
  .flex-order-gt-sm-14 {
    order: 14; }
  .flex-order-gt-sm-15 {
    order: 15; }
  .flex-order-gt-sm-16 {
    order: 16; }
  .flex-order-gt-sm-17 {
    order: 17; }
  .flex-order-gt-sm-18 {
    order: 18; }
  .flex-order-gt-sm-19 {
    order: 19; }
  .flex-order-gt-sm-20 {
    order: 20; }
  .offset-gt-sm-0, .flex-offset-gt-sm-0 {
    margin-left: 0%; }
  .offset-gt-sm-5, .flex-offset-gt-sm-5 {
    margin-left: 5%; }
  .offset-gt-sm-10, .flex-offset-gt-sm-10 {
    margin-left: 10%; }
  .offset-gt-sm-15, .flex-offset-gt-sm-15 {
    margin-left: 15%; }
  .offset-gt-sm-20, .flex-offset-gt-sm-20 {
    margin-left: 20%; }
  .offset-gt-sm-25, .flex-offset-gt-sm-25 {
    margin-left: 25%; }
  .offset-gt-sm-30, .flex-offset-gt-sm-30 {
    margin-left: 30%; }
  .offset-gt-sm-35, .flex-offset-gt-sm-35 {
    margin-left: 35%; }
  .offset-gt-sm-40, .flex-offset-gt-sm-40 {
    margin-left: 40%; }
  .offset-gt-sm-45, .flex-offset-gt-sm-45 {
    margin-left: 45%; }
  .offset-gt-sm-50, .flex-offset-gt-sm-50 {
    margin-left: 50%; }
  .offset-gt-sm-55, .flex-offset-gt-sm-55 {
    margin-left: 55%; }
  .offset-gt-sm-60, .flex-offset-gt-sm-60 {
    margin-left: 60%; }
  .offset-gt-sm-65, .flex-offset-gt-sm-65 {
    margin-left: 65%; }
  .offset-gt-sm-70, .flex-offset-gt-sm-70 {
    margin-left: 70%; }
  .offset-gt-sm-75, .flex-offset-gt-sm-75 {
    margin-left: 75%; }
  .offset-gt-sm-80, .flex-offset-gt-sm-80 {
    margin-left: 80%; }
  .offset-gt-sm-85, .flex-offset-gt-sm-85 {
    margin-left: 85%; }
  .offset-gt-sm-90, .flex-offset-gt-sm-90 {
    margin-left: 90%; }
  .offset-gt-sm-95, .flex-offset-gt-sm-95 {
    margin-left: 95%; }
  .offset-gt-sm-33, .flex-offset-gt-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-sm-66, .flex-offset-gt-sm-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center; }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 768px) {
  .flex-gt-sm {
    flex: 1 1 0%; } }

@media (min-width: 768px) {
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-0,
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-sm-0,
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-5,
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-5,
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-10,
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-10,
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-15,
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-15,
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-20,
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-20,
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-25,
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-25,
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-30,
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-30,
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-35,
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-35,
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-40,
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-40,
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-45,
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-45,
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-50,
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-50,
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-55,
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-55,
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-60,
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-60,
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-65,
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-65,
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-70,
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-70,
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-75,
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-75,
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-80,
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-80,
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-85,
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-85,
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-90,
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-90,
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-95,
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-95,
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-100,
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-100,
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-33, .layout-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33, .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-66, .layout-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66, .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-sm-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-sm-33, .layout-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33, .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-66, .layout-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66, .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-sm-column > .flex {
    min-height: 0; }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-sm-column {
    flex-direction: column; }
  .layout-gt-sm-row {
    flex-direction: row; } }

@media (min-width: 768px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none; }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-md--20 {
    order: -20; }
  .flex-order-md--19 {
    order: -19; }
  .flex-order-md--18 {
    order: -18; }
  .flex-order-md--17 {
    order: -17; }
  .flex-order-md--16 {
    order: -16; }
  .flex-order-md--15 {
    order: -15; }
  .flex-order-md--14 {
    order: -14; }
  .flex-order-md--13 {
    order: -13; }
  .flex-order-md--12 {
    order: -12; }
  .flex-order-md--11 {
    order: -11; }
  .flex-order-md--10 {
    order: -10; }
  .flex-order-md--9 {
    order: -9; }
  .flex-order-md--8 {
    order: -8; }
  .flex-order-md--7 {
    order: -7; }
  .flex-order-md--6 {
    order: -6; }
  .flex-order-md--5 {
    order: -5; }
  .flex-order-md--4 {
    order: -4; }
  .flex-order-md--3 {
    order: -3; }
  .flex-order-md--2 {
    order: -2; }
  .flex-order-md--1 {
    order: -1; }
  .flex-order-md-0 {
    order: 0; }
  .flex-order-md-1 {
    order: 1; }
  .flex-order-md-2 {
    order: 2; }
  .flex-order-md-3 {
    order: 3; }
  .flex-order-md-4 {
    order: 4; }
  .flex-order-md-5 {
    order: 5; }
  .flex-order-md-6 {
    order: 6; }
  .flex-order-md-7 {
    order: 7; }
  .flex-order-md-8 {
    order: 8; }
  .flex-order-md-9 {
    order: 9; }
  .flex-order-md-10 {
    order: 10; }
  .flex-order-md-11 {
    order: 11; }
  .flex-order-md-12 {
    order: 12; }
  .flex-order-md-13 {
    order: 13; }
  .flex-order-md-14 {
    order: 14; }
  .flex-order-md-15 {
    order: 15; }
  .flex-order-md-16 {
    order: 16; }
  .flex-order-md-17 {
    order: 17; }
  .flex-order-md-18 {
    order: 18; }
  .flex-order-md-19 {
    order: 19; }
  .flex-order-md-20 {
    order: 20; }
  .offset-md-0, .flex-offset-md-0 {
    margin-left: 0%; }
  .offset-md-5, .flex-offset-md-5 {
    margin-left: 5%; }
  .offset-md-10, .flex-offset-md-10 {
    margin-left: 10%; }
  .offset-md-15, .flex-offset-md-15 {
    margin-left: 15%; }
  .offset-md-20, .flex-offset-md-20 {
    margin-left: 20%; }
  .offset-md-25, .flex-offset-md-25 {
    margin-left: 25%; }
  .offset-md-30, .flex-offset-md-30 {
    margin-left: 30%; }
  .offset-md-35, .flex-offset-md-35 {
    margin-left: 35%; }
  .offset-md-40, .flex-offset-md-40 {
    margin-left: 40%; }
  .offset-md-45, .flex-offset-md-45 {
    margin-left: 45%; }
  .offset-md-50, .flex-offset-md-50 {
    margin-left: 50%; }
  .offset-md-55, .flex-offset-md-55 {
    margin-left: 55%; }
  .offset-md-60, .flex-offset-md-60 {
    margin-left: 60%; }
  .offset-md-65, .flex-offset-md-65 {
    margin-left: 65%; }
  .offset-md-70, .flex-offset-md-70 {
    margin-left: 70%; }
  .offset-md-75, .flex-offset-md-75 {
    margin-left: 75%; }
  .offset-md-80, .flex-offset-md-80 {
    margin-left: 80%; }
  .offset-md-85, .flex-offset-md-85 {
    margin-left: 85%; }
  .offset-md-90, .flex-offset-md-90 {
    margin-left: 90%; }
  .offset-md-95, .flex-offset-md-95 {
    margin-left: 95%; }
  .offset-md-33, .flex-offset-md-33 {
    margin-left: calc(100% / 3); }
  .offset-md-66, .flex-offset-md-66 {
    margin-left: calc(200% / 3); }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center; }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-md {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 768px) and (max-width: 1279px) {
  .flex-md {
    flex: 1 1 0%; } }

@media (min-width: 768px) and (max-width: 1279px) {
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-0,
  .layout-md-row > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-md-0,
  .layout-md-column > .flex-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-5,
  .layout-md-row > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-5,
  .layout-md-column > .flex-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-10,
  .layout-md-row > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-10,
  .layout-md-column > .flex-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-15,
  .layout-md-row > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-15,
  .layout-md-column > .flex-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-20,
  .layout-md-row > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-20,
  .layout-md-column > .flex-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-25,
  .layout-md-row > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-25,
  .layout-md-column > .flex-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-30,
  .layout-md-row > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-30,
  .layout-md-column > .flex-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-35,
  .layout-md-row > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-35,
  .layout-md-column > .flex-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-40,
  .layout-md-row > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-40,
  .layout-md-column > .flex-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-45,
  .layout-md-row > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-45,
  .layout-md-column > .flex-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-50,
  .layout-md-row > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-50,
  .layout-md-column > .flex-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-55,
  .layout-md-row > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-55,
  .layout-md-column > .flex-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-60,
  .layout-md-row > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-60,
  .layout-md-column > .flex-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-65,
  .layout-md-row > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-65,
  .layout-md-column > .flex-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-70,
  .layout-md-row > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-70,
  .layout-md-column > .flex-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-75,
  .layout-md-row > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-75,
  .layout-md-column > .flex-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-80,
  .layout-md-row > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-80,
  .layout-md-column > .flex-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-85,
  .layout-md-row > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-85,
  .layout-md-column > .flex-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-90,
  .layout-md-row > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-90,
  .layout-md-column > .flex-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-95,
  .layout-md-row > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-95,
  .layout-md-column > .flex-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-100,
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-100,
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-33, .layout-row > .flex-md-33, .layout-md-row > .flex-md-33, .layout-md-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-66, .layout-row > .flex-md-66, .layout-md-row > .flex-md-66, .layout-md-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-md-row > .flex {
    min-width: 0; }
  .layout-column > .flex-md-33, .layout-column > .flex-md-33, .layout-md-column > .flex-md-33, .layout-md-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-md-66, .layout-column > .flex-md-66, .layout-md-column > .flex-md-66, .layout-md-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-md-column > .flex {
    min-height: 0; }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-md-column {
    flex-direction: column; }
  .layout-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20; }
  .flex-order-gt-md--19 {
    order: -19; }
  .flex-order-gt-md--18 {
    order: -18; }
  .flex-order-gt-md--17 {
    order: -17; }
  .flex-order-gt-md--16 {
    order: -16; }
  .flex-order-gt-md--15 {
    order: -15; }
  .flex-order-gt-md--14 {
    order: -14; }
  .flex-order-gt-md--13 {
    order: -13; }
  .flex-order-gt-md--12 {
    order: -12; }
  .flex-order-gt-md--11 {
    order: -11; }
  .flex-order-gt-md--10 {
    order: -10; }
  .flex-order-gt-md--9 {
    order: -9; }
  .flex-order-gt-md--8 {
    order: -8; }
  .flex-order-gt-md--7 {
    order: -7; }
  .flex-order-gt-md--6 {
    order: -6; }
  .flex-order-gt-md--5 {
    order: -5; }
  .flex-order-gt-md--4 {
    order: -4; }
  .flex-order-gt-md--3 {
    order: -3; }
  .flex-order-gt-md--2 {
    order: -2; }
  .flex-order-gt-md--1 {
    order: -1; }
  .flex-order-gt-md-0 {
    order: 0; }
  .flex-order-gt-md-1 {
    order: 1; }
  .flex-order-gt-md-2 {
    order: 2; }
  .flex-order-gt-md-3 {
    order: 3; }
  .flex-order-gt-md-4 {
    order: 4; }
  .flex-order-gt-md-5 {
    order: 5; }
  .flex-order-gt-md-6 {
    order: 6; }
  .flex-order-gt-md-7 {
    order: 7; }
  .flex-order-gt-md-8 {
    order: 8; }
  .flex-order-gt-md-9 {
    order: 9; }
  .flex-order-gt-md-10 {
    order: 10; }
  .flex-order-gt-md-11 {
    order: 11; }
  .flex-order-gt-md-12 {
    order: 12; }
  .flex-order-gt-md-13 {
    order: 13; }
  .flex-order-gt-md-14 {
    order: 14; }
  .flex-order-gt-md-15 {
    order: 15; }
  .flex-order-gt-md-16 {
    order: 16; }
  .flex-order-gt-md-17 {
    order: 17; }
  .flex-order-gt-md-18 {
    order: 18; }
  .flex-order-gt-md-19 {
    order: 19; }
  .flex-order-gt-md-20 {
    order: 20; }
  .offset-gt-md-0, .flex-offset-gt-md-0 {
    margin-left: 0%; }
  .offset-gt-md-5, .flex-offset-gt-md-5 {
    margin-left: 5%; }
  .offset-gt-md-10, .flex-offset-gt-md-10 {
    margin-left: 10%; }
  .offset-gt-md-15, .flex-offset-gt-md-15 {
    margin-left: 15%; }
  .offset-gt-md-20, .flex-offset-gt-md-20 {
    margin-left: 20%; }
  .offset-gt-md-25, .flex-offset-gt-md-25 {
    margin-left: 25%; }
  .offset-gt-md-30, .flex-offset-gt-md-30 {
    margin-left: 30%; }
  .offset-gt-md-35, .flex-offset-gt-md-35 {
    margin-left: 35%; }
  .offset-gt-md-40, .flex-offset-gt-md-40 {
    margin-left: 40%; }
  .offset-gt-md-45, .flex-offset-gt-md-45 {
    margin-left: 45%; }
  .offset-gt-md-50, .flex-offset-gt-md-50 {
    margin-left: 50%; }
  .offset-gt-md-55, .flex-offset-gt-md-55 {
    margin-left: 55%; }
  .offset-gt-md-60, .flex-offset-gt-md-60 {
    margin-left: 60%; }
  .offset-gt-md-65, .flex-offset-gt-md-65 {
    margin-left: 65%; }
  .offset-gt-md-70, .flex-offset-gt-md-70 {
    margin-left: 70%; }
  .offset-gt-md-75, .flex-offset-gt-md-75 {
    margin-left: 75%; }
  .offset-gt-md-80, .flex-offset-gt-md-80 {
    margin-left: 80%; }
  .offset-gt-md-85, .flex-offset-gt-md-85 {
    margin-left: 85%; }
  .offset-gt-md-90, .flex-offset-gt-md-90 {
    margin-left: 90%; }
  .offset-gt-md-95, .flex-offset-gt-md-95 {
    margin-left: 95%; }
  .offset-gt-md-33, .flex-offset-gt-md-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-md-66, .flex-offset-gt-md-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center; }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) {
  .flex-gt-md {
    flex: 1 1 0%; } }

@media (min-width: 1280px) {
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-0,
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-md-0,
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-5,
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-5,
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-10,
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-10,
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-15,
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-15,
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-20,
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-20,
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-25,
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-25,
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-30,
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-30,
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-35,
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-35,
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-40,
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-40,
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-45,
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-45,
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-50,
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-50,
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-55,
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-55,
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-60,
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-60,
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-65,
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-65,
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-70,
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-70,
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-75,
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-75,
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-80,
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-80,
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-85,
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-85,
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-90,
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-90,
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-95,
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-95,
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-100,
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-100,
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-33, .layout-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33, .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-66, .layout-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66, .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-md-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-md-33, .layout-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33, .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-66, .layout-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66, .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-md-column > .flex {
    min-height: 0; }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-md-column {
    flex-direction: column; }
  .layout-gt-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none; }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-lg--20 {
    order: -20; }
  .flex-order-lg--19 {
    order: -19; }
  .flex-order-lg--18 {
    order: -18; }
  .flex-order-lg--17 {
    order: -17; }
  .flex-order-lg--16 {
    order: -16; }
  .flex-order-lg--15 {
    order: -15; }
  .flex-order-lg--14 {
    order: -14; }
  .flex-order-lg--13 {
    order: -13; }
  .flex-order-lg--12 {
    order: -12; }
  .flex-order-lg--11 {
    order: -11; }
  .flex-order-lg--10 {
    order: -10; }
  .flex-order-lg--9 {
    order: -9; }
  .flex-order-lg--8 {
    order: -8; }
  .flex-order-lg--7 {
    order: -7; }
  .flex-order-lg--6 {
    order: -6; }
  .flex-order-lg--5 {
    order: -5; }
  .flex-order-lg--4 {
    order: -4; }
  .flex-order-lg--3 {
    order: -3; }
  .flex-order-lg--2 {
    order: -2; }
  .flex-order-lg--1 {
    order: -1; }
  .flex-order-lg-0 {
    order: 0; }
  .flex-order-lg-1 {
    order: 1; }
  .flex-order-lg-2 {
    order: 2; }
  .flex-order-lg-3 {
    order: 3; }
  .flex-order-lg-4 {
    order: 4; }
  .flex-order-lg-5 {
    order: 5; }
  .flex-order-lg-6 {
    order: 6; }
  .flex-order-lg-7 {
    order: 7; }
  .flex-order-lg-8 {
    order: 8; }
  .flex-order-lg-9 {
    order: 9; }
  .flex-order-lg-10 {
    order: 10; }
  .flex-order-lg-11 {
    order: 11; }
  .flex-order-lg-12 {
    order: 12; }
  .flex-order-lg-13 {
    order: 13; }
  .flex-order-lg-14 {
    order: 14; }
  .flex-order-lg-15 {
    order: 15; }
  .flex-order-lg-16 {
    order: 16; }
  .flex-order-lg-17 {
    order: 17; }
  .flex-order-lg-18 {
    order: 18; }
  .flex-order-lg-19 {
    order: 19; }
  .flex-order-lg-20 {
    order: 20; }
  .offset-lg-0, .flex-offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-5, .flex-offset-lg-5 {
    margin-left: 5%; }
  .offset-lg-10, .flex-offset-lg-10 {
    margin-left: 10%; }
  .offset-lg-15, .flex-offset-lg-15 {
    margin-left: 15%; }
  .offset-lg-20, .flex-offset-lg-20 {
    margin-left: 20%; }
  .offset-lg-25, .flex-offset-lg-25 {
    margin-left: 25%; }
  .offset-lg-30, .flex-offset-lg-30 {
    margin-left: 30%; }
  .offset-lg-35, .flex-offset-lg-35 {
    margin-left: 35%; }
  .offset-lg-40, .flex-offset-lg-40 {
    margin-left: 40%; }
  .offset-lg-45, .flex-offset-lg-45 {
    margin-left: 45%; }
  .offset-lg-50, .flex-offset-lg-50 {
    margin-left: 50%; }
  .offset-lg-55, .flex-offset-lg-55 {
    margin-left: 55%; }
  .offset-lg-60, .flex-offset-lg-60 {
    margin-left: 60%; }
  .offset-lg-65, .flex-offset-lg-65 {
    margin-left: 65%; }
  .offset-lg-70, .flex-offset-lg-70 {
    margin-left: 70%; }
  .offset-lg-75, .flex-offset-lg-75 {
    margin-left: 75%; }
  .offset-lg-80, .flex-offset-lg-80 {
    margin-left: 80%; }
  .offset-lg-85, .flex-offset-lg-85 {
    margin-left: 85%; }
  .offset-lg-90, .flex-offset-lg-90 {
    margin-left: 90%; }
  .offset-lg-95, .flex-offset-lg-95 {
    margin-left: 95%; }
  .offset-lg-33, .flex-offset-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-lg-66, .flex-offset-lg-66 {
    margin-left: calc(200% / 3); }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center; }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-lg {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg {
    flex: 1 1 0%; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-0,
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-lg-0,
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-5,
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-5,
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-10,
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-10,
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-15,
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-15,
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-20,
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-20,
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-25,
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-25,
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-30,
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-30,
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-35,
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-35,
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-40,
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-40,
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-45,
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-45,
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-50,
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-50,
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-55,
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-55,
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-60,
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-60,
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-65,
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-65,
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-70,
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-70,
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-75,
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-75,
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-80,
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-80,
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-85,
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-85,
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-90,
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-90,
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-95,
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-95,
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-100,
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-100,
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-33, .layout-row > .flex-lg-33, .layout-lg-row > .flex-lg-33, .layout-lg-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-66, .layout-row > .flex-lg-66, .layout-lg-row > .flex-lg-66, .layout-lg-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-lg-row > .flex {
    min-width: 0; }
  .layout-column > .flex-lg-33, .layout-column > .flex-lg-33, .layout-lg-column > .flex-lg-33, .layout-lg-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-66, .layout-column > .flex-lg-66, .layout-lg-column > .flex-lg-66, .layout-lg-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-lg-column > .flex {
    min-height: 0; }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-lg-column {
    flex-direction: column; }
  .layout-lg-row {
    flex-direction: row; } }

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20; }
  .flex-order-gt-lg--19 {
    order: -19; }
  .flex-order-gt-lg--18 {
    order: -18; }
  .flex-order-gt-lg--17 {
    order: -17; }
  .flex-order-gt-lg--16 {
    order: -16; }
  .flex-order-gt-lg--15 {
    order: -15; }
  .flex-order-gt-lg--14 {
    order: -14; }
  .flex-order-gt-lg--13 {
    order: -13; }
  .flex-order-gt-lg--12 {
    order: -12; }
  .flex-order-gt-lg--11 {
    order: -11; }
  .flex-order-gt-lg--10 {
    order: -10; }
  .flex-order-gt-lg--9 {
    order: -9; }
  .flex-order-gt-lg--8 {
    order: -8; }
  .flex-order-gt-lg--7 {
    order: -7; }
  .flex-order-gt-lg--6 {
    order: -6; }
  .flex-order-gt-lg--5 {
    order: -5; }
  .flex-order-gt-lg--4 {
    order: -4; }
  .flex-order-gt-lg--3 {
    order: -3; }
  .flex-order-gt-lg--2 {
    order: -2; }
  .flex-order-gt-lg--1 {
    order: -1; }
  .flex-order-gt-lg-0 {
    order: 0; }
  .flex-order-gt-lg-1 {
    order: 1; }
  .flex-order-gt-lg-2 {
    order: 2; }
  .flex-order-gt-lg-3 {
    order: 3; }
  .flex-order-gt-lg-4 {
    order: 4; }
  .flex-order-gt-lg-5 {
    order: 5; }
  .flex-order-gt-lg-6 {
    order: 6; }
  .flex-order-gt-lg-7 {
    order: 7; }
  .flex-order-gt-lg-8 {
    order: 8; }
  .flex-order-gt-lg-9 {
    order: 9; }
  .flex-order-gt-lg-10 {
    order: 10; }
  .flex-order-gt-lg-11 {
    order: 11; }
  .flex-order-gt-lg-12 {
    order: 12; }
  .flex-order-gt-lg-13 {
    order: 13; }
  .flex-order-gt-lg-14 {
    order: 14; }
  .flex-order-gt-lg-15 {
    order: 15; }
  .flex-order-gt-lg-16 {
    order: 16; }
  .flex-order-gt-lg-17 {
    order: 17; }
  .flex-order-gt-lg-18 {
    order: 18; }
  .flex-order-gt-lg-19 {
    order: 19; }
  .flex-order-gt-lg-20 {
    order: 20; }
  .offset-gt-lg-0, .flex-offset-gt-lg-0 {
    margin-left: 0%; }
  .offset-gt-lg-5, .flex-offset-gt-lg-5 {
    margin-left: 5%; }
  .offset-gt-lg-10, .flex-offset-gt-lg-10 {
    margin-left: 10%; }
  .offset-gt-lg-15, .flex-offset-gt-lg-15 {
    margin-left: 15%; }
  .offset-gt-lg-20, .flex-offset-gt-lg-20 {
    margin-left: 20%; }
  .offset-gt-lg-25, .flex-offset-gt-lg-25 {
    margin-left: 25%; }
  .offset-gt-lg-30, .flex-offset-gt-lg-30 {
    margin-left: 30%; }
  .offset-gt-lg-35, .flex-offset-gt-lg-35 {
    margin-left: 35%; }
  .offset-gt-lg-40, .flex-offset-gt-lg-40 {
    margin-left: 40%; }
  .offset-gt-lg-45, .flex-offset-gt-lg-45 {
    margin-left: 45%; }
  .offset-gt-lg-50, .flex-offset-gt-lg-50 {
    margin-left: 50%; }
  .offset-gt-lg-55, .flex-offset-gt-lg-55 {
    margin-left: 55%; }
  .offset-gt-lg-60, .flex-offset-gt-lg-60 {
    margin-left: 60%; }
  .offset-gt-lg-65, .flex-offset-gt-lg-65 {
    margin-left: 65%; }
  .offset-gt-lg-70, .flex-offset-gt-lg-70 {
    margin-left: 70%; }
  .offset-gt-lg-75, .flex-offset-gt-lg-75 {
    margin-left: 75%; }
  .offset-gt-lg-80, .flex-offset-gt-lg-80 {
    margin-left: 80%; }
  .offset-gt-lg-85, .flex-offset-gt-lg-85 {
    margin-left: 85%; }
  .offset-gt-lg-90, .flex-offset-gt-lg-90 {
    margin-left: 90%; }
  .offset-gt-lg-95, .flex-offset-gt-lg-95 {
    margin-left: 95%; }
  .offset-gt-lg-33, .flex-offset-gt-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-lg-66, .flex-offset-gt-lg-66 {
    margin-left: calc(200% / 3); }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center; }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1920px) {
  .flex-gt-lg {
    flex: 1 1 0%; } }

@media (min-width: 1920px) {
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-0,
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-lg-0,
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-5,
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-5,
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-10,
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-10,
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-15,
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-15,
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-20,
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-20,
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-25,
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-25,
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-30,
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-30,
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-35,
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-35,
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-40,
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-40,
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-45,
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-45,
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-50,
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-50,
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-55,
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-55,
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-60,
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-60,
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-65,
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-65,
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-70,
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-70,
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-75,
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-75,
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-80,
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-80,
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-85,
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-85,
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-90,
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-90,
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-95,
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-95,
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-100,
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-100,
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-33, .layout-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33, .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-66, .layout-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66, .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-gt-lg-row > .flex {
    min-width: 0; }
  .layout-column > .flex-gt-lg-33, .layout-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33, .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-66, .layout-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66, .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-gt-lg-column > .flex {
    min-height: 0; }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-lg-column {
    flex-direction: column; }
  .layout-gt-lg-row {
    flex-direction: row; }
  .flex-order-xl--20 {
    order: -20; }
  .flex-order-xl--19 {
    order: -19; }
  .flex-order-xl--18 {
    order: -18; }
  .flex-order-xl--17 {
    order: -17; }
  .flex-order-xl--16 {
    order: -16; }
  .flex-order-xl--15 {
    order: -15; }
  .flex-order-xl--14 {
    order: -14; }
  .flex-order-xl--13 {
    order: -13; }
  .flex-order-xl--12 {
    order: -12; }
  .flex-order-xl--11 {
    order: -11; }
  .flex-order-xl--10 {
    order: -10; }
  .flex-order-xl--9 {
    order: -9; }
  .flex-order-xl--8 {
    order: -8; }
  .flex-order-xl--7 {
    order: -7; }
  .flex-order-xl--6 {
    order: -6; }
  .flex-order-xl--5 {
    order: -5; }
  .flex-order-xl--4 {
    order: -4; }
  .flex-order-xl--3 {
    order: -3; }
  .flex-order-xl--2 {
    order: -2; }
  .flex-order-xl--1 {
    order: -1; }
  .flex-order-xl-0 {
    order: 0; }
  .flex-order-xl-1 {
    order: 1; }
  .flex-order-xl-2 {
    order: 2; }
  .flex-order-xl-3 {
    order: 3; }
  .flex-order-xl-4 {
    order: 4; }
  .flex-order-xl-5 {
    order: 5; }
  .flex-order-xl-6 {
    order: 6; }
  .flex-order-xl-7 {
    order: 7; }
  .flex-order-xl-8 {
    order: 8; }
  .flex-order-xl-9 {
    order: 9; }
  .flex-order-xl-10 {
    order: 10; }
  .flex-order-xl-11 {
    order: 11; }
  .flex-order-xl-12 {
    order: 12; }
  .flex-order-xl-13 {
    order: 13; }
  .flex-order-xl-14 {
    order: 14; }
  .flex-order-xl-15 {
    order: 15; }
  .flex-order-xl-16 {
    order: 16; }
  .flex-order-xl-17 {
    order: 17; }
  .flex-order-xl-18 {
    order: 18; }
  .flex-order-xl-19 {
    order: 19; }
  .flex-order-xl-20 {
    order: 20; }
  .offset-xl-0, .flex-offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-5, .flex-offset-xl-5 {
    margin-left: 5%; }
  .offset-xl-10, .flex-offset-xl-10 {
    margin-left: 10%; }
  .offset-xl-15, .flex-offset-xl-15 {
    margin-left: 15%; }
  .offset-xl-20, .flex-offset-xl-20 {
    margin-left: 20%; }
  .offset-xl-25, .flex-offset-xl-25 {
    margin-left: 25%; }
  .offset-xl-30, .flex-offset-xl-30 {
    margin-left: 30%; }
  .offset-xl-35, .flex-offset-xl-35 {
    margin-left: 35%; }
  .offset-xl-40, .flex-offset-xl-40 {
    margin-left: 40%; }
  .offset-xl-45, .flex-offset-xl-45 {
    margin-left: 45%; }
  .offset-xl-50, .flex-offset-xl-50 {
    margin-left: 50%; }
  .offset-xl-55, .flex-offset-xl-55 {
    margin-left: 55%; }
  .offset-xl-60, .flex-offset-xl-60 {
    margin-left: 60%; }
  .offset-xl-65, .flex-offset-xl-65 {
    margin-left: 65%; }
  .offset-xl-70, .flex-offset-xl-70 {
    margin-left: 70%; }
  .offset-xl-75, .flex-offset-xl-75 {
    margin-left: 75%; }
  .offset-xl-80, .flex-offset-xl-80 {
    margin-left: 80%; }
  .offset-xl-85, .flex-offset-xl-85 {
    margin-left: 85%; }
  .offset-xl-90, .flex-offset-xl-90 {
    margin-left: 90%; }
  .offset-xl-95, .flex-offset-xl-95 {
    margin-left: 95%; }
  .offset-xl-33, .flex-offset-xl-33 {
    margin-left: calc(100% / 3); }
  .offset-xl-66, .flex-offset-xl-66 {
    margin-left: calc(200% / 3); }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start; }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center; }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end; }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xl {
    flex: 1;
    box-sizing: border-box; } }

@media screen\0  and (min-width: 1920px) {
  .flex-xl {
    flex: 1 1 0%; } }

@media (min-width: 1920px) {
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-0,
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xl-0,
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 0%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-5,
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-5,
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 5%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-10,
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-10,
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 10%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-15,
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-15,
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 15%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-20,
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-20,
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 20%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-25,
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-25,
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 25%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-30,
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-30,
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 30%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-35,
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-35,
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 35%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-40,
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-40,
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 40%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-45,
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-45,
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 45%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-50,
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-50,
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 50%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-55,
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-55,
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 55%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-60,
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-60,
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 60%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-65,
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-65,
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 65%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-70,
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-70,
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 70%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-75,
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-75,
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 75%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-80,
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-80,
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 80%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-85,
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-85,
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 85%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-90,
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-90,
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 90%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-95,
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-95,
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 95%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-100,
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-100,
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-33, .layout-row > .flex-xl-33, .layout-xl-row > .flex-xl-33, .layout-xl-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-66, .layout-row > .flex-xl-66, .layout-xl-row > .flex-xl-66, .layout-xl-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex, .layout-xl-row > .flex {
    min-width: 0; }
  .layout-column > .flex-xl-33, .layout-column > .flex-xl-33, .layout-xl-column > .flex-xl-33, .layout-xl-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-66, .layout-column > .flex-xl-66, .layout-xl-column > .flex-xl-66, .layout-xl-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-column > .flex, .layout-xl-column > .flex {
    min-height: 0; }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xl-column {
    flex-direction: column; }
  .layout-xl-row {
    flex-direction: row; }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none; }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; } }

@keyframes md-tab-content-hide {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }
  .slick-track:before {
    display: table;
    content: ''; }
  .slick-track:after {
    display: table;
    content: '';
    clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  outline: 0;
  background: none;
  opacity: .5;
  -webkit-transform: translateY(-50%) translateX(120%);
  -moz-transform: translateY(-50%) translateX(120%);
  -ms-transform: translateY(-50%) translateX(120%);
  -o-transform: translateY(-50%) translateX(120%);
  transform: translateY(-50%) translateX(120%);
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .slick-arrow:hover, .slick-arrow:active {
    opacity: 1; }
  .slick-arrow.slick-hidden {
    display: none; }
  .slick-arrow.slick-disabled {
    opacity: 0; }
  .slick-arrow.slick-prev {
    right: auto;
    left: 0;
    -webkit-transform: translateY(-50%) translateX(-120%);
    -moz-transform: translateY(-50%) translateX(-120%);
    -ms-transform: translateY(-50%) translateX(-120%);
    -o-transform: translateY(-50%) translateX(-120%);
    transform: translateY(-50%) translateX(-120%); }
  .slick-arrow.slick-prev, .slick-arrow.slick-next {
    border: none;
    height: 36px;
    width: 36px; }
    .slick-arrow.slick-prev:before, .slick-arrow.slick-prev:after, .slick-arrow.slick-next:before, .slick-arrow.slick-next:after {
      display: none; }
    .slick-arrow.slick-prev .ico, .slick-arrow.slick-next .ico {
      font-size: 16px;
      color: #333333; }
  .slick-arrow.slick-next .chevron-right {
    -webkit-transform: scale(-1, 1) translateX(50%) translateY(-50%);
    -moz-transform: scale(-1, 1) translateX(50%) translateY(-50%);
    -ms-transform: scale(-1, 1) translateX(50%) translateY(-50%);
    -o-transform: scale(-1, 1) translateX(50%) translateY(-50%);
    transform: scale(-1, 1) translateX(50%) translateY(-50%); }

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

*:before,
*:after {
  box-sizing: border-box; }

html,
input,
select,
textarea {
  font-family: "Open Sans", Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px; }

input,
textarea {
  color: #333333; }

html, body {
  height: 100%;
  width: 100%;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  font-weight: 400; }

img {
  vertical-align: middle; }

a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  line-height: 24px; }

select {
  -webkit-appearance: none; }

select::-ms-expand {
  display: none; }

p {
  line-height: 30px; }

button {
  line-height: 24px; }

ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

dl, ul {
  font-size: 1rem; }

.container {
  width: auto;
  margin: 0 auto;
  max-width: 1230px;
  width: 100%;
  padding: 0 0.9375em; }
  @media screen and (min-width: 35.5em) {
    .container {
      padding: 0 1.5625em; } }
  @media screen and (min-width: 48em) {
    .container {
      padding: 0 0.9375em; } }

#cookiesEU-box {
  position: fixed !important;
  bottom: 0px !important;
  left: 0px !important;
  max-width: 950px; }
  #cookiesEU-box a, #cookiesEU-box p {
    font-size: 12px;
    line-height: 16px; }
  #cookiesEU-box .cookiesEU-text {
    padding-right: 30px !important; }

main > .layout-row {
  position: relative; }

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff; }

.bg-repeat {
  background-repeat: repeat;
  color: #fff; }

img {
  max-width: 100%;
  height: auto; }

iframe {
  max-width: 100%; }

h1 {
  font-size: 2.25rem; }

h2 {
  font-size: 1.875rem; }

h3 {
  font-size: 1.75rem; }

h4 {
  font-size: 1.5rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1.125rem; }

.f-light {
  font-weight: 300; }

.f-reg {
  font-weight: 400; }

.f-bold {
  font-weight: 700; }

.text-light {
  color: #fff; }

.text-dark {
  color: #333333; }

a {
  color: inherit; }

address {
  font-style: normal;
  font-size: 1rem;
  line-height: 30px; }

.card-item {
  perspective: 1000px;
  transform-style: preserve-3d;
  outline: 0; }
  .card-item.flipped .flipper {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg); }
  .card-item .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative; }
    .card-item .flipper .img-wrap {
      cursor: pointer; }
  .card-item .front, .card-item .back {
    backface-visibility: hidden;
    position: relative; }
  .card-item .front {
    z-index: 2;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transform: rotateY(0deg);
    background: #fff; }
    .text-light .card-item .front {
      background: #333333; }
  .card-item .back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    cursor: pointer; }
    .card-item .back .img-wrap {
      margin-bottom: 2.1875em; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-item .flipper {
    overflow: hidden; }
  .card-item .back {
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear;
    -webkit-transform: rotateY(0) translateY(100%);
    -moz-transform: rotateY(0) translateY(100%);
    -ms-transform: rotateY(0) translateY(100%);
    -o-transform: rotateY(0) translateY(100%);
    transform: rotateY(0) translateY(100%); }
  .card-item.flipped .flipper {
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
    -ms-transform: rotateY(0);
    -o-transform: rotateY(0);
    transform: rotateY(0); }
  .card-item.flipped .back {
    z-index: 3;
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%); } }

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  display: none; }

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel.program-carousel {
  display: none; }
  .owl-carousel.program-carousel md-tab-label p {
    text-align: center;
    margin-bottom: 10px;
    color: #333333; }
  @media screen and (max-width: 48em) {
    .owl-carousel.program-carousel {
      display: block; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: #fff; }
  .header .container {
    display: flex;
    padding: 0; }
  .header .logo-wrap {
    margin-left: 0.9375em;
    width: auto;
    margin-right: auto;
    flex: none; }
    .header .logo-wrap a {
      display: block; }
    .header .logo-wrap img {
      height: 3.75em;
      width: auto; }
  .header md-menu-bar {
    margin-left: auto; }
  @media screen and (min-width: 60em) {
    .header .logo-wrap img {
      height: 5.3125em; }
    .header md-menu-bar {
      margin-left: 0; } }

.front-menu {
  width: 100%;
  display: flex; }
  .front-menu ul {
    margin-left: auto;
    display: none;
    align-items: stretch;
    list-style-type: none;
    min-height: 85px; }
  .front-menu ul ~ md-menu-bar {
    display: block; }
  .front-menu li {
    -webkit-transition: background 0.15s linear;
    -moz-transition: background 0.15s linear;
    transition: background 0.15s linear; }
    .front-menu li a {
      color: #333333;
      font-size: 0.75rem;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      display: block;
      padding: 15px;
      -webkit-transition: color 0.15s linear;
      -moz-transition: color 0.15s linear;
      transition: color 0.15s linear; }
    .front-menu li.active {
      background: #2b7ec3; }
      .front-menu li.active a {
        color: #fff; }
  @media screen and (min-width: 60em) {
    .front-menu ul {
      display: flex; }
    .front-menu ul ~ md-menu-bar {
      display: none; } }

.gallery-grid {
  margin-left: auto; }

.gallery-item {
  height: 16.5625em;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  outline: 0; }
  .gallery-item .description {
    background: #2b7ec3;
    color: #fff;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    transition: all 0.15s linear; }
    .gallery-item .description p {
      margin: 0;
      padding: 0.875em 1.25em; }
  .gallery-item.hovered .description {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%); }

.table-gray {
  border: 1px solid #d0d0d0;
  width: 100%;
  border-collapse: collapse; }
  .table-gray h3 {
    font-size: 1.125rem; }
  .table-gray thead {
    text-transform: uppercase; }
  .table-gray td {
    padding: 18px 22px;
    border-bottom: 1px solid #d0d0d0;
    border-right: 1px solid #d0d0d0;
    width: 50%;
    vertical-align: top;
    color: #333333;
    background: #fff; }
    .table-gray td p {
      line-height: 24px;
      font-size: 0.75rem;
      margin-top: 24px; }
  .table-gray tbody td {
    font-size: 0.875rem; }
    .table-gray tbody td:first-of-type {
      width: 140px;
      white-space: nowrap; }
    .table-gray tbody td:last-of-type {
      border-right: 0; }
  .table-gray h1, .table-gray h2, .table-gray h3, .table-gray h4, .table-gray h5, .table-gray h6, .table-gray p {
    margin: 0; }
    .table-gray h1 small, .table-gray h2 small, .table-gray h3 small, .table-gray h4 small, .table-gray h5 small, .table-gray h6 small, .table-gray p small {
      margin-top: 10px;
      line-height: 30px; }
  .table-gray ~ .table-gray {
    border-top: 0; }
  @media screen and (min-width: 48em) {
    .table-gray {
      border-left: 0; }
      .table-gray td:first-child {
        border-left: 4px solid #2b7ec3; } }
  @media screen and (max-width: 35.4375em) {
    .table-gray {
      display: block;
      margin-bottom: 3.125em; }
      .table-gray h3 {
        font-size: 1rem; }
      .table-gray thead, .table-gray tbody, .table-gray tr, .table-gray td, .table-gray th {
        display: block;
        width: 100%;
        font-size: 0.75rem;
        text-align: center; }
      .table-gray thead td {
        color: #fff;
        background: #666666; }
      .table-gray tbody td:first-of-type {
        display: block;
        font-weight: 700;
        width: 100%;
        color: #fff;
        background: #999999;
        padding-left: 0; } }

.content table:not(.table-gray) {
  max-width: 100%; }
  .content table:not(.table-gray) td {
    padding: 10px 10px 10px 0;
    font-size: 14px;
    line-height: 24px;
    vertical-align: top; }
  @media screen and (max-width: 35.5em) {
    .content table:not(.table-gray) {
      display: block;
      width: 100% !important;
      height: auto !important; }
      .content table:not(.table-gray) td {
        display: block; } }

.ft-top .socials {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em; }
  .ft-top .socials li {
    flex: 1; }
  .ft-top .socials a {
    padding: 0;
    text-decoration: none; }
    .ft-top .socials a:hover .ico {
      color: #000; }
  .ft-top .socials .ico {
    font-size: 24px;
    background: none;
    margin: 0 10px;
    height: 25px;
    width: 25px;
    color: #333333; }

@media screen and (min-width: 48em) {
  .ft-top .socials {
    margin-right: 2em; } }

section {
  position: relative;
  display: flex;
  padding-bottom: 2.5em; }
  section [layout] {
    position: relative;
    z-index: 1; }
    section [layout] > div {
      width: 100%; }
    section [layout] > [class*="flex-offset-"] {
      width: 0; }
  section:not(.promo):before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #2b7ec3;
    height: 2.0625em;
    width: 100%; }
  section header {
    position: relative;
    z-index: 1; }
    section header h2 {
      text-transform: uppercase;
      color: #fff;
      text-align: center;
      margin: 0;
      display: inline-block;
      min-width: 25%;
      padding: 12px 20px;
      background: #ffd200; }
  @media screen and (max-width: 35.46875em) {
    section header h2 {
      font-size: 1.125rem; } }
  @media screen and (min-width: 35.5em) and (max-width: 59.9375em) {
    section header h2 {
      font-size: 1.25rem; } }
  @media screen and (min-width: 47.96875em) {
    section:not(.promo) .container {
      position: relative; }
      section:not(.promo) .container:before {
        content: "";
        background: #f2f2f2;
        position: absolute;
        top: 0;
        left: 0.9375em;
        opacity: .4;
        height: -webkit-calc(100% + 40px);
        height: calc(100% + 40px);
        width: -webkit-calc(25% - 10px);
        width: calc(25% - 10px); }
    section:not(.promo).text-light .container:before {
      background: #333333;
      opacity: .6; }
    section.sec-contact .container:before {
      height: 100%; } }
  @media screen and (min-width: 60em) {
    section {
      min-height: -webkit-calc(100vh - 85px);
      min-height: calc(100vh - 85px); }
      section:not(.promo) .container:before {
        width: -webkit-calc(33.3333% - 10px);
        width: calc(33.3333% - 10px); }
      section header h2 {
        min-width: 33.3333%; } }

.content {
  padding: 1.25em 0; }
  .content p {
    margin: 0; }
  .content a {
    font-weight: 700;
    text-decoration: none;
    color: inherit; }
  @media screen and (min-width: 48em) {
    .content {
      padding: 1.875em 2.5em; } }

.side-info {
  color: #fff;
  margin: 2.5em 0;
  padding: 0.625em 0.9375em;
  background: #2b7ec3; }
  .side-info p:first-of-type {
    margin: 0; }
  .side-info p:last-of-type {
    margin-bottom: 0; }
  @media screen and (min-width: 48em) {
    .side-info {
      margin: 3.75em 0; } }
  @media screen and (min-width: 60em) {
    .side-info {
      padding: 1em 2.5em; } }

.bb-top {
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: #fff;
  background: #2b7ec3;
  border-radius: 50%;
  z-index: 1;
  height: 42px;
  width: 42px; }
  .bb-top .arrow-up {
    font-size: 20px;
    left: 2px;
    position: relative; }
    .bb-top .arrow-up:before {
      display: inline-block;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg); }
  @media screen and (min-width: 60em) {
    .bb-top .arrow-up {
      left: 1px; } }

.promo {
  min-height: 100vh;
  display: flex;
  align-items: center; }
  .promo .md-button-yellow {
    margin-left: 15px; }
  @media screen and (min-width: 35.5em) {
    .promo .md-button-yellow {
      margin-left: 55px; } }

@media screen and (min-width: 48em) and (-ms-high-contrast: active), screen and (min-width: 48em) and (-ms-high-contrast: none) {
  .promo {
    height: 100vh; } }

.promo-title {
  max-width: 45.625em;
  margin-top: 1.875em;
  padding: 3.4375em 0.9375em 0.9375em; }
  .promo-title h2 {
    font-size: 1.5rem;
    line-height: 30px;
    color: #ffd200; }
    .promo-title h2 small {
      display: block;
      font-size: 1.125rem;
      color: #fff; }
  .text-dark .promo-title h2 {
    color: #333333; }
    .text-dark .promo-title h2 small {
      color: #595959; }
  @media screen and (min-width: 35.5em) {
    .promo-title {
      margin-top: 3.75em;
      padding: 3.4375em; }
      .promo-title h2 {
        font-size: 2.25rem;
        line-height: 48px; }
        .promo-title h2 small {
          font-size: 2.25rem; } }

.promo-date {
  border-left: 9px solid #ffd200;
  padding: 0 1.375em;
  margin-left: 0.9375em;
  width: -webkit-calc(100% - 15px);
  width: calc(100% - 15px); }
  .promo-date p {
    font-size: 1.125rem;
    line-height: 30px;
    font-weight: 300; }
  @media screen and (min-width: 35.5em) {
    .promo-date {
      margin-left: 3.4375em;
      width: -webkit-calc(100% - 55px);
      width: calc(100% - 55px); }
      .promo-date p {
        font-size: 1.875rem;
        line-height: 48px; } }

.sec-calendarium [layout-wrap] {
  justify-content: flex-end; }

.sec-calendarium .content {
  padding: 1.875em 0;
  position: relative;
  z-index: 2; }

.sec-calendarium .md-button-small {
  margin-top: 3.125em; }

@media screen and (min-width: 48em) {
  .sec-calendarium .content {
    margin-top: 7.5em; } }

.sec-calendarium .program-tab {
  display: block; }
  @media screen and (max-width: 48em) {
    .sec-calendarium .program-tab {
      display: none; } }

.sec-payment .side-info p {
  font-size: 1.5rem; }

.sec-payment header h2 {
  margin-bottom: 20px; }

.sec-payment .content {
  max-width: 550px; }

.sec-payment .flex {
  display: flex;
  justify-content: center;
  align-items: flex-end; }

.sec-payment .p-payment {
  margin: 0 0 40px; }
  .sec-payment .p-payment li > span {
    margin: 10px; }

@media screen and (min-width: 48em) {
  .sec-payment header h2 {
    margin-bottom: 40px; }
  .sec-payment .p-payment li > span {
    margin: 10px auto 0; } }

@media screen and (min-width: 80em) {
  .sec-payment .p-payment li > span {
    margin: 0 0 0 auto; } }

.list-unstyled {
  line-height: 30px; }
  .list-unstyled span {
    display: inline-block; }
  .list-unstyled strong {
    font-size: 1.5rem; }
    .list-unstyled strong span {
      font-size: 1rem;
      position: relative;
      top: -10px;
      left: -5px; }
  .list-unstyled ~ span {
    font-size: 0.75rem; }
  .list-unstyled li {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline; }
    .list-unstyled li span {
      line-height: 28px; }

.simple-list {
  margin-bottom: 40px;
  line-height: 30px; }
  .simple-list li:before {
    content: "-";
    display: inline-block;
    margin-right: 8px; }

@media screen and (min-width: 48em) {
  .sec-accomodation .container > .content, .sec-events .container > .content {
    padding-left: 3.75em; } }

.acc-grid {
  flex-wrap: wrap;
  justify-content: center; }

.acc-item {
  margin-bottom: 3em;
  padding: 0;
  width: 100%; }
  .acc-item .front > div {
    background: whitesmoke; }
    .text-light .acc-item .front > div {
      background: #333333; }
    .acc-item .front > div > div {
      padding: 1.25em; }
  .acc-item .back {
    background: #2b7ec3;
    color: #fff;
    padding: 1.25em;
    outline: 0; }
    .text-light .acc-item .back {
      background: #333333; }
  .acc-item .img-wrap {
    position: relative;
    height: 10.3125em;
    background-color: #2b7ec3;
    background-size: cover;
    background-position: center; }
  .acc-item .ico-bed {
    color: #fff;
    font-size: 100px;
    background: none; }
  .acc-item .ico-star {
    color: #fff;
    font-size: 80px;
    background: none; }
  .acc-item h3 {
    margin: 0;
    position: relative;
    font-size: 1.125rem; }
    .acc-item h3 ~ p {
      margin-bottom: 40px; }
  .acc-item p {
    font-size: 1rem;
    line-height: 30px;
    margin: 0; }
  .acc-item a {
    font-weight: 400; }
    .acc-item a:hover, .acc-item a:active {
      color: #2b7ec3; }
  @media screen and (min-width: 48em) {
    .acc-item {
      padding: 0.625em 0.6875em; } }

.sec-gallery {
  padding-bottom: 0; }
  .sec-gallery .container > .content {
    padding: 1.875em 0; }
  @media screen and (min-width: 48em) {
    .sec-gallery .container > .content {
      padding: 3.125em 2.5em 2.5em 3.75em; } }

.sec-partners a {
  display: inline-block;
  padding: 5px 25px; }

.sec-partners img {
  max-width: 12.5em;
  height: auto; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .sec-partners img {
      width: 100%; } }

.sec-partners .content > .inl-block {
  text-align: center; }

@media screen and (min-width: 60em) {
  .sec-partners img {
    max-width: 16.25em; } }

.sec-contact {
  padding-bottom: 0; }
  .sec-contact [layout] {
    position: static; }
  .sec-contact article {
    padding: 1.375em 0;
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0.7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B3FFFFFF,endColorstr=#B3FFFFFF);
    zoom: 1; }
    .ie9 .sec-contact article {
      filter: none; }
    .sec-contact article:last-of-type {
      padding-bottom: 6.25em; }
  .sec-contact h3 {
    font-size: 1.125rem;
    margin-bottom: 25px; }
  .sec-contact.text-light article {
    background-color: transparent;
    background-color: rgba(51, 51, 51, 0.7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#B3333333,endColorstr=#B3333333);
    zoom: 1; }
    .ie9 .sec-contact.text-light article {
      filter: none; }
  @media screen and (min-width: 35.5em) {
    .sec-contact article {
      padding: 1.375em 1.375em; } }

@media screen and (min-width: 48em) and (-ms-high-contrast: active), screen and (min-width: 48em) and (-ms-high-contrast: none) {
  .sec-contact {
    height: -webkit-calc(100vh - 85px);
    height: calc(100vh - 85px); } }
  @media screen and (min-width: 48em) {
    .sec-contact article {
      padding: 1.375em 2.375em; }
    .sec-contact h3 {
      margin-bottom: 35px; } }
  @media screen and (max-width: 47.96875em) {
    .sec-contact [layout] > [class*=flex-offset-] {
      width: 100%; } }
  .sec-contact .contact-form md-input-container input, .sec-contact .contact-form md-input-container textarea {
    border: 1px solid #eee;
    width: 100%;
    padding: 10px 15px; }
    .sec-contact .contact-form md-input-container input.ng-invalid.ng-invalid-required.ng-touched, .sec-contact .contact-form md-input-container textarea.ng-invalid.ng-invalid-required.ng-touched {
      border: 1px solid #dd2c00; }
  .sec-contact .contact-form md-input-container textarea {
    resize: none; }
  .sec-contact .contact-form md-input-container label {
    margin: 10px 0px;
    display: block; }
  .sec-contact .contact-form simple-captcha {
    margin-top: 20px;
    display: block; }
    .sec-contact .contact-form simple-captcha input {
      border: 1px solid #eee;
      padding: 10px 15px; }
  .sec-contact .contact-form .txt-center {
    text-align: center; }

.map-container {
  width: 100%;
  height: 100%;
  margin-top: 1.25em; }
  @media screen and (min-width: 48em) {
    .map-container {
      position: absolute;
      top: 3.125em;
      left: 0.9375em;
      margin-top: 0;
      height: -webkit-calc(100% - 70px);
      height: calc(100% - 70px);
      width: -webkit-calc(25% - 10px);
      width: calc(25% - 10px); } }
  @media screen and (min-width: 60em) {
    .map-container {
      top: 4.0625em;
      width: -webkit-calc(33.3333% - 10px);
      width: calc(33.3333% - 10px); } }

.event h3 {
  line-height: 30px; }
  .event h3 small {
    display: block;
    font-size: 1rem;
    opacity: 0.7; }

.event-info {
  margin: 1.25em 0 0;
  line-height: 24px;
  font-size: 1rem; }
  .event-info span {
    display: block; }
    .event-info span:before, .event-info span:after {
      content: " ";
      display: table; }
    .event-info span:after {
      clear: both; }
  .event-info strong {
    float: right;
    margin: 0 10px;
    font-weight: 700;
    font-size: 1rem; }

/*# sourceMappingURL=theme_fifth.min.css.map */